import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=bb6f27ba&scoped=true&"
import script from "./FAQ.vue?vue&type=script&lang=js&"
export * from "./FAQ.vue?vue&type=script&lang=js&"
import style0 from "./FAQ.vue?vue&type=style&index=0&id=bb6f27ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb6f27ba",
  null
  
)

/* custom blocks */
import block0 from "../assets/I18N/FAQ.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CFAQ.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports