<template>
    <md-content>

    <!--produce content -->

    <!-- <span class="md-display-2">{{ $t("title") }}</span> -->
    <div class="md-mt-10 md-ml-5">
        <h1>{{ $t("title") }}</h1>
    </div>
    <div class="container " style="overflow-x: hidden; min-height:  500px;">
    
        <div class="md-layout md-gutter mt-20 productContainer custom-scrollbar" v-if="this.Products.length > 0">
            <div class="md-layout-item md-layout md-alignment-center-center" v-for="(item, i) in Products" :key="i">
                <div class="md-layout-item mb-100 produit">
                    <a @click="navigateTo(item)" class="md-layout md-layout-item" style="position:relative ;height: 100%; max-width: 300px; display: block; margin: auto auto;">
                        <div class="overlay">
                            <div class="md-size-100" >
                              <h6>{{item.title}}</h6>
                              <!--
                                <span v-if="item.qty > 0"><h5>${{item.price}} <span v-if="item.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{item.discount}}</span></h5></span>
                                <span v-else><h5>{{ $t("section.unavailable") }}</h5></span> 
                              -->
                              <span><h5>${{item.price}} <span v-if="item.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{item.discount}}</span></h5></span>
                            </div>
                          </div>
                          <img v-if="item.image.url" class=" md-size-100 md-alignment-center-center productImg md-mt-3" :src="item.image.url" :truesrc="item.image.url"/>
                          <img v-else class=" md-size-100 md-alignment-center-center productImg md-mt-3" src="https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg" />
                    </a>
                </div>
            </div>
        </div>
    
    </div>
    
    </div>

    </md-content>

</template>
<i18n>
    {
      "en": {
        "title": "Our featured products"
      },
      "fr": {
        "title": "Nos produits vedettes"
      }
    }
</i18n>
<style scoped>

.overlay { position:absolute; bottom: 0;width: 100%;height: 30%; }
.produit .overlay div { position:absolute; bottom: 0;width: 100% }
.produit:hover .overlay {background-color: rgba(var(--primary), 0.6); }

.md-discount { color: rgb(212, 44, 44);}

.container {display:block; margin-right: auto !important; margin-left: auto !important; margin-top: 50px !important;}

.productContainer {max-height: 750px; overflow-y: scroll;}
.productContainer::-webkit-scrollbar { width: 1px !important; color: rgb(var(--primary));}

.produit { height: 350px; width: 300px; position: relative; display: block; margin: auto auto !important; cursor: pointer;}
.produit a:hover {text-decoration:none; }
/* .produit:hover h6 {color: rgb(var(--primary));} */

.productImg {max-height: 280px; height: auto;  width: 100%; min-width: 250px; max-width: 300px; margin: auto auto; background-color: white;}

/* a img:hover {background: linear-gradient(to bottom left, #fff 0%, #fff 50%, #2fa0c2 50%, #2fa0c2 100%); } */

.custom-scrollbar { max-height: auto; overflow-y: scroll; scrollbar-width: thin; scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); }

@supports (scrollbar-color: auto) { .custom-scrollbar { scrollbar-color: rgb(var(--primary))  rgba(var(--secondary),0.1); scrollbar-width: thin; } }
    /* Styles pour la barre de défilement - Edge (Chromium) */
@supports (-ms-overflow-style: none) { .custom-scrollbar { scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); scrollbar-width: thin; } }

</style>
<script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
        //import pageTranslations from '../../src/assets/I18N/Home.json';
        import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

    
    
    export default {
      name: 'products',
      mixins: [apiECommerceExtension],
      data: () =>({
    
        Products:[],
        // Products:[
        //     {id: 1, title:'E-GP Air Électronique', price: '1328.00', path:'', accountingNumber: 'E-GP Air', image: { url:'/Resources/Images/imagesHome/e-gpair.png',}, promo: false } ,
        //     {id: 2, title:'E-Turtle Hi-Vis Électronique', price: '1100.00', accountingNumber: 'E-Turtle HI', image: { url:'Resources/Images/imagesHome/etvh.png',}, promo: false  },
        //     {id: 3, title:'E-Turtle Électronique NOIR ', price: '1035.00', accountingNumber: 'E-Turtle elec', image: { url:'Resources/Images/imagesHome/e-tn.png',}, promo: false  },
        //     {id: 4, title:'TOURING NOIR: Veste toute saison', price: '1229.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_17_0.png',}, promo: false  },
        //     {id: 5, title:'TOURING GRISE: Veste toute saison', price: '1229.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_19_0.png',}, promo: false  },
        //     {id: 6, title:'TURTLE 2 NOIR Gilet AirBag Moto', price: '905.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/t_20_0.png',}, promo: false  },
        //     {id: 7, title:'Turtle 2 Hi-vis Gilet AirBag Moto', price: '985.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/t_21_0.png',}, promo: false  },
        //     {id: 8, title:'AIRNEST NOIR : Gilet AirBag Moto', price: '649.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_a.png',}, promo: false  },
        //     {id: 9, title:'GPAIR 2 : Gilet AirBag pour piste', price: '1135.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_25_0.png',}, promo: false  },
        //     {id: 10, title:'ROADSTER NOIR: Cuir AirBag Noir', price: '1395.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_2_0.png',}, promo: false  },
        //     {id: 11, title:'ROADSTER VINTAGE: Cuir AirBag', price: '1395.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_22_0.png',}, promo: false  },
        //     {id: 12, title:'VENTED: Blouson été', price: '1089.00', accountingNumber: 'TOURING NOIR', image: { url:'Resources/Images/imagesHome/_23_0.png',}, promo: false  },
        // ]
      }),
      mounted: async function () {

        //this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
        //this.$i18n.mergeLocaleMessage('en', pageTranslations.en);
        let filter = {"categoryId": 30};
        this.Products = await this.EcommerceProductsListWithFilter(filter,this.range,this.callback);

      },
      methods:{
        navigateTo: function (item) {
            if(item.technicalURL) {
                this.$router.push(item.technicalURL);
                //window.location = window.location.origin + item.technicalURL;
            }
            else if(item.id) {
                this.$router.push('/product/' + item.id);
                //window.location = window.location.origin + '/product/' + item.id;
            }
            else {
                console.log('product not defined!')
                console.log(item);
            }
           

        //   switch (item.id) {
        //     case 1:
        //       window.location = window.location.origin + '/gp_air';
        //       break;
        //     case 2:
        //       window.location = window.location.origin + '/turtle_hi_vis';          
        //       break;
        //     case 3:
        //       window.location = window.location.origin + '/turtle_noir';         
        //       break;
        //     case 4:
        //       window.location = window.location.origin + '/touring_noire';   
        //       break;
        //     case 5:
        //       window.location = window.location.origin + '/touring_grise';  
        //       break;
        //     case 6:
        //       window.location = window.location.origin + '/turtle_noir2';  
        //       break;
        //     case 7:
        //       window.location = window.location.origin + '/turtle_hi_vis2';  
        //       break;
        //     case 8:
        //       window.location = window.location.origin + '/airnestNoir';  
        //       break;
        //     case 9:
        //       window.location = window.location.origin + '/gp_air2';  
        //       break;
        //     case 10:
        //       window.location = window.location.origin + '/roadster_noir';   
        //       break;
        //     case 11:
        //       window.location = window.location.origin + '/roadster_vintage';  
        //       break;
        //     case 12:
        //       window.location = window.location.origin + '/vented';  
        //       break;
        //     default:
        //       break;
        //   }
          
        },
        callback: function (response) {
            this.response = response;
            this.range = $App.getRangeHeader(response);
        },
        
      },
    
      components: {
          //"Diapo": Diapo,
          
      }
    }
    </script>