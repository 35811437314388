<template>
  <div id="app">
    <Cookie/>
    <cHeader />
    <router-view/>
    <cFooter />

    <md-dialog class="md-error" :md-active.sync="errorVisible">
          <md-progress-bar class="md-accent" :md-value=100 md-mode="determinate"></md-progress-bar>
          <md-dialog-title class="md-accent">Erreur d'application</md-dialog-title>
          <md-tabs>
              <!-- md-dynamic-height -->
              <md-tab md-label="Général">
                  Une erreur c'est produite, veuillez essayer de nouveau !<br />
                  si le problème persiste veuillez-contacter l'administrateur du système.
              </md-tab>
              <md-tab md-label="Détail">
                  {{errorMessage}}
              </md-tab>
          </md-tabs>
          <md-dialog-actions>
              <md-button class="md-accent" @click="errorVisible = false">OK</md-button>
          </md-dialog-actions>
      </md-dialog>

      <md-snackbar   :md-active.sync="notifyVisible" md-persistent >
        <span v-if="notify" class="md-body-1">{{notify.message}}</span>
        <md-button @click="notifyVisible = false"><md-icon>close</md-icon></md-button>
    </md-snackbar>

  </div>
</template>

<style>
  

@import '../public/App_Themes/Main/Main.css';
@import '../public/App_Themes/Main/Theme.css'; 
@import '../public/App_Themes/Main/spacing-modif.css';
@import '../public/App_Themes/Main/xzoom.css';
@import '../node_modules/vue-inner-image-zoom/lib/vue-inner-image-zoom.css';


@import url("https://fonts.googleapis.com/css?family=Material+Icons");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div.md-snackbar { background-color: rgb(var(--primary3)) !important;}
div.md-snackbar i { color: #ffff !important;}

/*
#nav { padding: 30px; }
#nav a {font-weight: bold; color: #2c3e50; }
#nav a.router-link-exact-active {color: #42b983;} 
*/
</style>
<script>

import cHeader from '@/components/content-header.vue'
import cFooter from '@/components/content-footer.vue'
import Cookie from '../src/components/cookie.vue';

export default {
  name: 'App',

  data: () => ({
    errorVisible: false,
    error: null,
    errorMessage: "",
    notify: null,
    notifyVisible: false
  }),
  mounted: async function () {
    
    $App.event.$on("app_error", this.appErrorhappened);
    $App.event.$on("app_notify", this.onAppNotify);

  },
  methods:{
    appErrorhappened(params) {
      if (params instanceof Error) {
        this.errorMessage = "name:" + params.name + " msg: " + params.message
      } else { this.errorMessage = params; }
      this.errorVisible = true;

      console.error(params);
      //alert(params);
    },
    onAppNotify: function (e) {
      this.notify = e;
      this.notifyVisible = true;
    },

  },
  components: {
    Cookie,
    "cHeader": cHeader,
    "cFooter": cFooter,
  }
}
</script>
