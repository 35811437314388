<template>
    <div>
        <div class="default-pad" style="min-height:550px;"> 
            <div class="md-layout mt-30">
                <div class="md-layout-item md-layout">
                    
                    <div class="md-layout-item md-size-100">
                        <h1>{{ $t("billing.title") }}</h1>
                    </div>
                    <div class="md-layout-item md-layout md-size-100">
                        <h2 class="md-pl-3 md-pt-2"  >{{ $t("billing.slogan") }}</h2>
                    </div>
                    
                        
                     <div v-if="this.order" class="md-layout-item md-layout md-size-75 md-small-size-100 md-mt-5">
                        <!--Info Billing col-->
                        <div class="md-layout-item md-layout md-size-50 md-small-size-100 pr-20">
                            <div class="md-layout-item md-layout md-size-100">
                                <h2 class="gridTitleStyle">{{ $t("billing.infoBilling") }}</h2>
                            </div>
                            <div class="md-layout-item md-layout md-size-100">
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field :class="{'error': error.name == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'error': error.name == true }">{{ $t("billing.name") }}</label>
                                        <md-input @change="error.name = false" v-model="order.account.name" clearable required></md-input>                                       
                                    </md-field>
                                    <span class="error" style="margin-top: -20px !important;" v-if="error.name == true">{{ $t("billing.nameError") }}</span>
                                </div>
                                <!-- <div class="md-layout-item md-layout md-size-100">
                                    <md-field :class="{'error': error.phone == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'error': error.phone == true }">{{ $t("billing.phone") }}</label>
                                        <md-input @change="error.phone = false" v-model="order.account.phone.number" clearable required></md-input>                                       
                                    </md-field>
                                    <span class="error" style="margin-top: -20px !important;" v-if="error.phone == true">{{ $t("billing.phoneError") }}</span>
                                </div> -->
                                
                                <div class="md-layout-item md-layout md-size-100 " >
                                    <field-phone ax-id="orderBillingPhone" @change="error.phone = false"  class="md-layout-item md-size-100 " :class="{'error': error.phone == true }"  mode="edit" v-bind:item.sync="order.account.phone" :required="true" :includeType="false" :readonly="false"  />
                                    <span class="error" style="margin-top: -20px !important;"  v-if="error.phone == true">{{ $t("billing.phoneError") }}</span>
                                </div>
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field :class="{'error': error.email == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'error': error.email == true }">{{ $t("billing.email") }}</label>
                                        <md-input @change="error.email = false" v-model="order.account.email.address" clearable required></md-input>
                                    </md-field>
                                    <span class="error" style="margin-top: -20px !important;" v-if="error.email == true" >{{ $t("billing.emailError") }}</span>
                                </div>
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field style="background-color: rgba(var(--primary),0.1);">
                                        <label>{{ $t("billing.comment") }}</label>
                                        <md-textarea v-model="order.comment"></md-textarea>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        
                        <!--Adress Billing col-->
                        <div class="md-layout-item md-layout md-size-50 md-small-size-100 pr-20">
                            <div class="md-layout-item md-size-100">
                                <h2 class="gridTitleStyle">{{ $t("billing.address") }}</h2>
                            </div>
                            <div class="md-layout-item md-layout md-size-100">
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field  :class="{'warning': error.street == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'warning': error.street == true }">{{ $t("billing.street") }}</label>
                                        <md-input @change="error.street = false" v-model="order.account.address.street" required></md-input>
                                    </md-field>
                                    <span class="warning" style="margin-top: -20px !important;" v-if="error.street == true" >{{ $t("billing.streetWarning") }}</span>
                                </div>
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field :class="{'warning': error.city == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'warning': error.city == true }">{{ $t("billing.city") }}</label>
                                        <md-input @change="error.city = false" v-model="order.account.address.city.name" required></md-input>
                                    </md-field>
                                    <span class="warning" style="margin-top: -20px !important;" v-if="error.city == true" >{{ $t("billing.cityWarning") }}</span>
                                </div>
                                <div class="md-layout-item md-layout md-size-100">
                                    <!-- <md-field style="background-color: rgba(var(--primary),0.1);">
                                        <label for="Province">{{ $t("billing.state") }}</label>
                                        <md-select v-model="order.account.address.city.state.name"  name="Province" id="Province">
                                            <md-option value="Quebec">Québec</md-option>
                                            <md-option value="Ontario">Ontario</md-option>
                                            <md-option value="Colombie-Britanique">Colombie-Britanique</md-option>
                                            <md-option value="L’Île-du-Prince-Édouard">L’Île-du-Prince-Édouard</md-option>
                                            <md-option value="Manitoba">Manitoba</md-option>
                                            <md-option value="Nouveau-Brunswick">Nouveau-Brunswick</md-option>
                                            <md-option value="Nouvelle-Écosse">Nouvelle-Écosse</md-option>
                                            <md-option value="Alberta">Alberta</md-option>
                                            <md-option value="Saskatchewan">Saskatchewan</md-option>
                                            <md-option value="Terre-Neuve-et-Labrador">Terre-Neuve-et-Labrador</md-option>
                                        </md-select>
                                    </md-field> -->
                                    <field-state ax-id='orderBillingState' v-if="order.account.address.city" title="Province *" v-bind:stateId.sync="order.account.address.city.stateId" @md-changed="onStateChanged" :readonly="false" />  <!--@update etc...-->

                                </div>
                                <!-- <div class="md-layout-item md-layout md-size-100">
                                    <md-field style="background-color: rgba(var(--primary),0.1);">
                                        <label for="Pays">{{ $t("billing.country") }}</label>
                                        <md-select v-model="order.account.address.city.state.country.name" name="Pays" id="Pays">
                                            <md-option value="Canada">Canada</md-option>
                                        </md-select>
                                    </md-field>
                                </div> -->
                                <div class="md-layout-item md-layout md-size-100">
                                    <md-field :class="{'warning': error.zipCode == true }" style="background-color: rgba(var(--primary),0.1);">
                                        <label :class="{'warning': error.zipCode == true }">{{ $t("billing.zipCode") }}</label>
                                        <md-input @change="error.zipCode = false" v-model="order.account.address.zipCode" required></md-input>
                                    </md-field>
                                    <span class="warning" style="margin-top: -20px !important;" v-if="this.error.zipCode == true" >{{ $t("billing.zipCodeWarning") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Summary Step-->
                    <div class="md-layout-item md-layout">
                        <Summary step="billingInfo" v-bind:item.sync="order" v-bind:dirty.sync="isDirty" @eCommerceNext="Updated" @eCommerceBack="back" />
                    </div>  
                </div>
            </div>
        </div>
       
    </div>

</template>
<i18n src="../../../assets/I18N/eCommerce/order.json"></i18n>
<i18n>
    {
      "en": {
        "title": "Title1"
      },
      "fr": {
        "title": "Titre1"
      }
    }
  </i18n>
<style>
    div[ax-id='orderBillingPhone'] .md-field { background-color: rgba(var(--primary),0.1); }
    div[ax-id='orderBillingState'] .md-field { background-color: rgba(var(--primary),0.1); }
</style>
<style scoped>
    .gridTitleStyle {text-align:left; padding:10px 0px 10px 0px !important;}
    .inlineBlock {display:inline-block;}
    .default-pad {padding: 0px 0px 0px 20px;}


    @media handheld, only screen and (max-width: 600px) {

    }
    @media handheld, only screen and (max-width: 960px) {

    }
    @media handheld, only screen and (max-width: 1264px) {}

</style>

<script>
import Summary from '@/components/infoPayment.vue'
import field_phone from'@/components/Commons/phone.vue'

import field_state from '@/components/Commons/Fields/field-state.vue';


import { apiECommerceExtension } from '@/mixins/apiECommerceExtension';


export default {
    name: 'order_billing',
    mixins:  [apiECommerceExtension],
    props: {
        //summary: Object,
    },
    data: () => ({
        order: null,
        otherShippingAdresse: false,
        livraison: true,
        ShippingDate:{min:""},
        error:{
          name: false,
          phone: false,
          email: false,
          street: false,
          city: false,
          zipCode: false,
        },
        isDirty: false
    }),
    watch: {
    livraison: function() {
      // Fonction appelée lorsqu'une modification est détectée
      
      if(this.livraison == false) {
        this.otherShippingAdresse = false;
      }else {
        this.otherShippingAdresse = true;
      }
    },

  },
    mounted: async function () {

        if(!$App.onlineStore) { this.$router.replace('/'); }

        this.order = await this.GetOrder();

        // if(!this.order.account.address.city.state.name){ this.order.account.address.city.state.name = "Quebec"; }
        // if(!this.order.account.address.city.state.country.name){ this.order.account.address.city.state.country.name = "Canada"; }
        // if(this.order.account.address.typeId == 0){ this.order.account.address.typeId = 1;}

        this.validation();
    },
    methods: {
        validation: async function(){
          let valid = true;
          let validation = this.order.validations;

          if(this.order.account.address.city.stateId == 0){ valid = false; }

          let errorFlag = {name: false, phone: false, email: false, street: false, city: false, zipCode: false };
          if(validation) {

            validation.forEach(e => {
              if(e.severity == 3){
                if(e.field == "Account.Name") { valid = false; errorFlag.name = true; }
                if(e.field == "Account.Phone.Number") { valid = false; errorFlag.phone = true; }
                if(e.field == "Account.Email.Address") { valid = false; errorFlag.email = true; }
                if(e.field == "Address.Street") {  errorFlag.street = true; }
                if(e.field == "Address.ZipCode") { errorFlag.zipCode = true; }
                if(e.field == "Address.City.Name") { errorFlag.city = true; }
              }

            });
            if(valid == false){this.error = errorFlag;}
          }
          return valid;
        },
        Updated: async function() {
            let valid = await this.validation();
            if(valid == true) { this.navigateTo(); }
      
        },
        onStateChanged: function (state) {
            if (this.order && this.order.account && this.order.account.address && this.order.account.address.city) {
                this.order.account.address.city.state = state;
                if (state) {
                    this.order.account.address.city.stateId = state.id;
                }
                else { this.order.account.address.city.stateId = 0; }
            }
            this.isDirty = true;
            },
        navigateTo: function () {
            this.$router.push('/order/shipping');
        },
        back: function(){this.$router.push('/order')},
    },
    components: {
            'Summary' : Summary,
            'field-phone' : field_phone,
            'field-state': field_state,
        }
}

</script>