import { render, staticRenderFns } from "./order.vue?vue&type=template&id=2808fbce&scoped=true&"
import script from "./order.vue?vue&type=script&lang=js&"
export * from "./order.vue?vue&type=script&lang=js&"
import style0 from "./order.vue?vue&type=style&index=0&id=2808fbce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2808fbce",
  null
  
)

/* custom blocks */
import block0 from "../../../assets/I18N/eCommerce/order.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CeCommerce%5COrder%5Corder.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./order.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports