<template>
    <div v-if="ready">
        <md-field>
            <label>{{this.title}}</label>
            <md-select v-model="val" @md-selected="onSelected" :placeholder="emptyValue" :disabled="isReadOnly" md-dense>
                <md-option v-if="dataSource" v-for="(a, index) in dataSource" :index="index" :key="index" :value="a.id">{{a[propertyToShow]}}</md-option>
            </md-select>
            <md-button @click="onClear" class="md-icon-button md-dense">
                <md-icon>clear</md-icon>
            </md-button>
        </md-field>

    </div>
</template>
<style scoped>
</style>
<script>
    import { apiExtension } from '@/mixins/apiExtension.js';

    export default {
        name: 'field-select-wd',
        mixins: [apiExtension],
        props: {
            title: String,
            item: Object,
            source: Array,
            sourceURI: String,
            defaultValue: Number,
            property: String,
            expand: Boolean,
            readonly: Boolean,
            caption: String
        },

        data: () => ({
            val: 0,
            dataSource: null,
            itemWatch: null,
        }),
        watch: {
            val: {
                handler(e) {
                    if (this.item != null) {
                        this.item[this.property] = e;
                    }
                }
            }
        },
        computed: {
            ready: async function () {
                let show = false;
                if (this.item != null) {
                    if (this.item[this.property] == null) {
                        if (this.defaultValue != null) { this.val = this.defaultValue; }
                    } else {
                        this.val = this.item[this.property];
                    }

                    if (!this.itemWatch) {
                        this.itemWatch = this.$watch('item.' + this.property, this.onItemChanged);
                    }

                    show = true;
                } else if (this.defaultValue != null) {
                    this.val = this.defaultValue;
                }
                return show;
            },
            emptyValue: function () {
                if (this.defaultValue) {
                    return '[' + this.defaultValue + ']';
                } else { return null };
            },
            isReadOnly: function () {
                return this.readonly; // || !this.isEdit;
            },
            propertyToShow: function () {
                if (this.caption) { return this.caption; }
                else { return 'title'; }
            }
        },
        mounted: async function () {
            await this.dataSourceRead();
        },
        methods: {
            dataSourceRead: async function () {
                if (this.source) {
                    this.dataSource = this.source;
                }
                else if (this.sourceURI) {
                    let data = await this.httpApiGet(this.sourceURI);
                    if (data) {
                        this.dataSource = data;
                    }
                    else { this.dataSource = []; }
                }
            },
            read: async function (id) {
                let current = null;
                if (id) {
                    if (typeof this.sourceURI == 'string') {
                        let data = await this.httpApiGet(this.sourceURI + '/' + id);
                        if (data) { current = data; }
                    }
                }
                return current;
            },
            onSelected: async function (id) {
                let current = null;
                if (this.expand) {
                    current = await this.read(id);
                } else {
                    if (this.dataSource) {
                        let srce = this.dataSource.find(e => e.id == id);
                        if (srce) { current = srce; }
                    }
                }

                this.$emit('md-selected', current, this.item);
            },
            onClear: function () {
                if (!this.isReadOnly) {
                    if (this.item != null) {
                        this.item[this.property] = this.defaultValue; // null;
                    }
                    this.val = this.defaultValue;
                }
            },
            onItemChanged: function (newVal, oldVal) {
                //console.log('watch item...');
                if (!this.isReadOnly) {
                    if (this.val != newVal) {
                        this.val = newVal;
                    }
                }
            }
        }
    }

</script>