<template>
    <div style="position: relative;"> <!--POsition relative for z-index to check in final production FXGL**-->
        <Carousel :v-bind="settings" :perPage='1' :centerMode="true" :autoplay="true" :autoplayTimeout="2000" :speed="2000" :loop="true" :infinite="true" :paginationEnabled="false" :mouse-drag="false" :zIndex="0">
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Accident_French.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Airbag3D_francais.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Turtle_Airnest_french.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/GP-AIR_french.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Touring_french.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Vented_french.jpg" title="" alt="" /></slide>
            <slide><img class="imgWidth" src="../../public/Resources/Images/diapo/Xena_french.jpg" title="" alt="" /></slide>
        </Carousel>
    </div>
</template>
<style scoped>
   /*@import '../../public/App_Themes/Main/slick.css';
    @import '../../public/App_Themes/Main/slick-theme.css';*/
    .imgWidth {
        width:100%;
    }
</style>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'diapo',
    data() {
        return {
            settings: {
                arrows: false,
                //autoplaySpeed: 3000,
                fade: true,
                dots: false,
            }
        }
    },
    components: { Carousel, Slide},
}

</script>