<template>
    <div>
        <md-autocomplete v-model="autocompleteSelected" :md-options="autocompleteStatesDataSource" :disabled="isReadOnly"  @md-changed="onChanged" @md-selected="onSelected">
            <label>{{title}}</label>
            <!-- :md-open-on-focus="false" -->
        </md-autocomplete>
    </div>
</template>
<style scoped>
</style>
<script>
    import { autocompleteExtension } from '@/mixins/autocompleteExtension.js'

    export default {
        name: 'field-state',
        mixins: [autocompleteExtension],
        props: {
            title: String,
            stateId: Number,
            readonly: Boolean            
        },
        data: () => ({
            dataSource: null,
            autocompleteStatesDataSource: [],
            autocompleteSelected: null
        }),
        watch: {
            stateId: {
                handler(e) {
                    this.acSetValue();
                }
            }
        },
        computed: {
            isReadOnly: function () {
                return this.readonly;
            }
        },        
        mounted: async function () {
            await this.dataSourceRead();

            // Set autocomplete
            // Attention ne gere pas les accents (les enleve)
            if (this.autocompleteStatesDataSource) {

                this.acSetValue();
            }
        },
        methods: {
            dataSourceRead: async function () {
                let api = $App.url.api + "States" + "?$Expand=country";

                // Show progress bar
                $App.event.$emit("app_running", true);

                try {
                    let token = $App.getToken();
                    
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            let data = await response.json();
                            this.dataSource = data;
                            this.autocompleteStatesDataSource = this.autocompleteDataSource(data, function (ref) { return ref.name + ', ' + ref.country.abbreviation; });

                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                // Hide progress bar
                $App.event.$emit("app_running", false);
            },
            acSetValue: function(){
                let a = this.autocompleteStatesDataSource.find(e => e.ref.id == this.stateId);
                if(a) { this.autocompleteSelected = a; }
            },
            onChanged: function(e){
                if(e ==="") {
                    //console.log("onChanged");
                    this.$emit('update:stateId', 0);
                    this.$emit('md-changed', null);
                }
            },
            onSelected: function (item) {
                //console.log("onSelected");
                let state = item.ref;
                this.$emit('update:stateId', state.id);
                this.$emit('md-changed', state);
            },
        }
    }
</script>