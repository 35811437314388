<template>
  <md-content>

    <!-- <Diapo/> -->

    <div class="md-layout">
      <video width="100%" controls autoplay muted>
        <source :src="$t('videos.HMOOV')" type="video/mp4">
        Votre navigateur ne prend pas en charge la lecture de la vidéo.
      </video>
    </div>


    <div class="default-pad">

      <!--Text content-->
      <div class="md-layout mt-50">
        <div class="md-layout-item md-layout txtHelite">
          <div class="md-layout-item md-size-100">
            <h3  v-html="$t('intro.title')"></h3>
          </div>
          <div class="md-layout-item md-size-100 mt-10"><p v-html="$t('intro.text')"></p>
          </div>
        </div>
      </div>

      <!--text content-->
      <div class="md-layout">
       <div class="md-layout-item md-layout txtHelite">
         <div class="md-layout-item md-size-100 mt-30">
          <h3  v-html="$t('intro.title2')"></h3>
         </div>
         <div class="md-layout-item md-size-100 mt-10"><p v-html="$t('intro.text2')"></p>
         </div>
       </div>
      </div>


      <a id="dimension"></a>
      <div class="md-layout mt-80 mb-80">
        <div class="md-layout-item md-layout md-size-100">
          <h3  v-html="$t('dimension.title')"></h3>
        </div>
        <div class="md-layout-item mt-10">
          <md-tabs class="md-transparent">
            <md-tab id="tab-measure" name="tab-measure" :md-label="$t('dimension.tab-measure')" md-icon="straighten">
              <table class="measureTable mt-50" width="100%" >
                <thead>
                  <tr>
                    <th>{{$t('dimension.grid-measureJacket.header')}}</th>
                    <th>{{$t('dimension.grid-measureJacket.header-drawing')}}</th>
                    <th>{{$t('dimension.grid-measureJacket.description')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-A')}}</strong></td>
                    <td rowspan="6" style="text-align: center; background-color: #fff !important;"><img src="/Resources/Images/size-chart-tshirt-man.jpg" /></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-A')"></p></td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-B')}}</strong></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-B')"></p></td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-C')}}</strong></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-C')"></p></td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-D')}}</strong></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-D')"></p></td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-E')}}</strong></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-E')"></p></td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-measureJacket.header-F')}}</strong></td>
                    <td><p v-html="$t('dimension.grid-measureJacket.description-F')"></p></td>
                  </tr>
                </tbody>
              </table>
              <table class="measureTable mt-50" width="100%" >
                <thead>
                  <tr>
                    <th>{{$t('dimension.grid-measureVest.header')}}</th>
                    <th>{{$t('dimension.grid-measureVest.header-drawing')}}</th>
                    <th>{{$t('dimension.grid-measureVest.description')}}</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td><strong>{{$t('dimension.grid-measureVest.header-A')}}</strong></td>
                      <td rowspan="6" style="text-align: center; background-color: #fff !important;"><img src="/Resources/Images/size-chart-e.jpg" /></td>
                      <td><p v-html="$t('dimension.grid-measureVest.description-A')"></p></td>
                    </tr>
                    <tr>
                      <td><strong>{{$t('dimension.grid-measureVest.header-B')}}</strong></td>
                      <td><p v-html="$t('dimension.grid-measureVest.description-B')"></p></td>
                    </tr>
                    <tr>
                      <td><strong>{{$t('dimension.grid-measureVest.header-C')}}</strong></td>
                      <td><p v-html="$t('dimension.grid-measureVest.description-C')"></p></td>
                    </tr>
                    <tr>
                      <td><strong>{{$t('dimension.grid-measureVest.header-D')}}</strong></td>
                      <td><p v-html="$t('dimension.grid-measureVest.description-D')"></p></td>
                    </tr>
                </tbody>
              </table>
            </md-tab>
            <md-tab id="tab-jacket" name="tab-jacket" :md-label="$t('dimension.tab-jacket')" md-icon="table_chart">
              <table class="sizeTable mt-50" md-card width="100%">
                <thead>
                  <tr>
                    <th>{{$t('dimension.grid-sizeJacket.header')}}</th>
                    <th>S</th>
                    <th>M</th>
                    <th>L</th>
                    <th>XL</th>
                    <th>2XL</th>
                    <th>3XL</th>
                    <th>4XL</th>
                    <th>5XL</th>
                    <th>6XL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-A')}}</strong></td>
                    <td>160-175</td>
                    <td>170-180</td>
                    <td>175-185</td>
                    <td>180-190</td>
                    <td>180-195</td>
                    <td>180-200</td>
                    <td>180-205</td>
                    <td>180-205</td>
                    <td>180-210</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-B')}}</strong></td>
                    <td>75-90</td>
                    <td>85-95</td>
                    <td>90-105</td>
                    <td>100-110</td>
                    <td>105-115</td>
                    <td>110-120</td>
                    <td>115-130</td>
                    <td>120-140</td>
                    <td>130-150</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-C')}}</strong></td>
                    <td>70-80</td>
                    <td>75-90</td>
                    <td>85-100</td>
                    <td>90-105</td>
                    <td>95-110</td>
                    <td>100-120</td>
                    <td>105-130</td>
                    <td>110-135</td>
                    <td>120-140</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-D')}}</strong></td>
                    <td>75-90</td>
                    <td>85-100</td>
                    <td>95-110</td>
                    <td>100-115</td>
                    <td>105-120</td>
                    <td>110-125</td>
                    <td>115-130</td>
                    <td>115-130</td>
                    <td>115-130</td>
                   </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-E')}}</strong></td>
                    <td>55-70</td>
                    <td>60-75</td>
                    <td>65-80</td>
                    <td>65-85</td>
                    <td>65-90</td>
                    <td>70-95</td>
                    <td>70-100</td>
                    <td>70-100</td>
                    <td>70-100</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-F')}}</strong></td>
                    <td>57-65</td>
                    <td>62-70</td>
                    <td>65-72</td>
                    <td>65-75</td>
                    <td>65-77</td>
                    <td>65-80</td>
                    <td>67-82</td>
                    <td>67-85</td>
                    <td>67-90</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-G')}}</strong></td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>100cc</td>
                    <td>100cc</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeJacket.header-H')}}</strong></td>
                    <td>MO.TX.TRG-BK-S</td>
                    <td>MO.TX.TRG-BK-M</td>
                    <td>MO.TX.TRG-BK-L</td>
                    <td>MO.TX.TRG-BK-XL</td>
                    <td>MO.TX.TRG-BK-2XL</td>
                    <td>MO.TX.TRG-BK-3XL</td>
                    <td>MO.TX.TRG-BK-4XL</td>
                    <td>MO.TX.TRG-BK-5XL</td>
                    <td>MO.TX.TRG-BK-6XL</td>
                  </tr>
                </tbody>
              </table>
            </md-tab>
            <md-tab id="tab-vest" name="tab-vest" :md-label="$t('dimension.tab-vest')" md-icon="table_chart">
              <table class="sizeTable mt-50" md-card width="100%">
                <thead>
                  <tr>
                    <th>{{$t('dimension.grid-sizeVest.header')}}</th>
                    <th>XS</th>
                    <th>S</th>
                    <th>M</th>
                    <th>L</th>
                    <th>L-L</th>
                    <th>XL</th>
                    <th>XL-L</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-A')}}</strong></td>
                    <td>150-165</td>
                    <td>160-175</td>
                    <td>165-185</td>
                    <td>170-190</td>
                    <td>175-200</td>
                    <td>170-190</td>
                    <td>175-200</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-B')}}</strong></td>
                    <td>78-87</td>
                    <td>85-94</td>
                    <td>92-101</td>
                    <td>99-108</td>
                    <td>106-115</td>
                    <td>113-112</td>
                    <td>120-129</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-C')}}</strong></td>
                    <td>70-79</td>
                    <td>77-86</td>
                    <td>84-93</td>
                    <td>91-100</td>
                    <td>98-107</td>
                    <td>105-114</td>
                    <td>112-121</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-D')}}</strong></td>
                    <td>77-86</td>
                    <td>84-93</td>
                    <td>91-100</td>
                    <td>98-107</td>
                    <td>105-114</td>
                    <td>112-121</td>
                    <td>119-128</td>
                      </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-E')}}</strong></td>
                    <td>61</td>
                    <td>64</td>
                    <td>67</td>
                    <td>72</td>
                    <td>77</td>
                    <td>72</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <td><strong>{{$t('dimension.grid-sizeVest.header-F')}}</strong></td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>60cc</td>
                    <td>100cc</td>
                    <td>100cc</td>
                  </tr>
  
                </tbody>

                <!-- <md-table-toolbar style="background-color: #017CBD; color: #FFFFFF; text-align: center; font-size: 11px; padding-left: 50%;">
                    FEMME
                </md-table-toolbar> -->
              </table>
              <p class="md-layout md-alignment-center-left md-mt-3">{{$t('favorMeasurement')}}</p>
            </md-tab>
        </md-tabs>
    </div>
      </div>


      <!--youtube video-->
      <!-- <div class="md-layout mt-30 mb-80">
        <div class="md-layout-item md-layout md-size-100">
          <h3  v-html="$t('collection.title')"></h3>
        </div>
        <div class="md-layout-item mt-10">
            <div class="center">
              <iframe src="https://www.youtube.com/embed/mI5fA6HNrn4" style="width:620px; height:350px;"></iframe>
            </div>
        </div>
      </div> -->
     
    </div>
  </md-content>
</template>
<i18n src="../assets/I18N/Home.json"></i18n>
<i18n>
  {
    "en": {
      "favorMeasurement": "* favor these measurements when choosing the size."
    },
    "fr": {
      "favorMeasurement": "* privilégier ces mesure pour le choix de la taille."
    }
  }
</i18n>
<style scoped>

    .measureTable { border-collapse: collapse; }
    .measureTable thead tr {
        background-color: #017CBD;
        color:#FFFFFF;
        font-size: 11px;
        padding: 10px; height: 56px;
    }
    .measureTable tbody { font-size: 11px; text-align: left; }
    .measureTable tbody tr { border-bottom: 1px solid #ccc; }
    .measureTable tbody tr:hover { background-color: #eee; }
    .measureTable tbody tr td { padding: 8px; }

    .sizeTable { border-collapse: collapse; }
    .sizeTable thead tr {
        background-color: #017CBD;
        color:#FFFFFF;
        font-size: 11px;
        padding: 10px; height: 56px;
    }
    .sizeTable tbody { font-size: 11px; }
    .sizeTable tbody tr { border-bottom: 1px solid #ccc; }
    .sizeTable tbody tr:hover { background-color: #eee; }
    .sizeTable tbody tr td { padding: 8px; }
    .sizeTable tbody tr td:nth-child(1) { text-align: left; }

  /* Styles pour la barre de défilement - Firefox */
  @supports (scrollbar-color: auto) {  /* .custom-scrollbar { scrollbar-color: rgb(var(--primary))  rgba(var(--secondary),0.1); scrollbar-width: thin; }  */ }
    /* Styles pour la barre de défilement - Edge (Chromium) */
  @supports (-ms-overflow-style: none) { }
  @media handheld, only screen and (max-width: 600px) { }
  @media handheld, only screen and (max-width: 958px) { }
  @media handheld, only screen and (max-width: 1264px) { }

</style>
<script>
    import Diapo from '@/components/diapo.vue'
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

export default {
  name: 'home',
  mixins: [apiECommerceExtension],
  data: () =>({
  }),
  mounted: async function () {
  },
  methods:{
  },

  components: {
      "Diapo": Diapo,
  }
}
</script>
