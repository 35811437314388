<template>
    <md-content>
        <div class="default-pad">  

            <!--top content-->
            <div class="md-layout txtHelite mt-40">

                <!--top left content-->
                <div class="md-layout-item md-size-30 md-small-size-100 xzoom-container center mt-10"> 
                    <inner-image-zoom ref="principalIMG" src="Resources/Images/preview/2021-ethv.png" zoomSrc="Resources/Images/original/2021-ethv.png" />
					<div class="xzoom-thumbs">
					    <a @click="changeImage('Resources/Images/preview/2021-ethv.png','Resources/Images/original/2021-ethv.png')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/2021-ethv.png"></a>
					    <a @click="changeImage('Resources/Images/preview/2021-ethv_2.png','Resources/Images/original/2021-ethv_2.png')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/2021-ethv_2.png"></a>
					    <a @click="changeImage('Resources/Images/preview/2021-ethv_3.png','Resources/Images/original/2021-ethv_3.png')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/2021-ethv_3.png"></a>
					</div>
                </div>

                <!--space between layout content-->
                <div class="md-layout-item md-size-10"></div>

                <!--top right content-->
                <div class="md-layout-item md-size-60 md-small-size-100" v-if="Product">
                    <div class="md-layout-item md-size-100 mt-10 mb-10">
                        <h3>{{Product.title}}</h3>
                    </div>
                    <div class="md-layout-item md-size-100"> 
                        <p class="price">
                            <span class="md-price"> {{Product.price}}$</span><span v-if="Product.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{Product.discount}}</span>
                        </p>                            
                </div>
                    <div class="md-layout-item md-size-100 mt-20"> 
                        <p v-html="$options.filters.textWithBR(Product.description)"></p>
                    </div>
                    <div class="md-layout-item md-size-100 mt-70"> 
                        <div class="md-layout-item md-size-100 mb-10"> 
                            <h3 v-html="$t('manual.title')"></h3>
                        </div>
                        <div class="md-layout-item md-layout">
                            <div class="md-layout-item md-size-30">
                                <a href="Resources/Documents/2020-04-USER_GUIDE_E-TURTLE.pdf" target="_blank">
                                    <img class="border_img" src="Resources/Images/user_guide_e-turtle.jpg">
                                </a>
                               </div>
                               <div class="md-layout-item md-size-40 txtproHelite ml-40"> 
                                <p v-html="$t('manual.text')" class="pb-25"></p>
                                <a href="Resources/Documents/2020-04-USER_GUIDE_E-TURTLE.pdf" class="button" target="_blank" style="color:white;" v-html="$t('manual.download')"></a>
                                <a v-if="isOnlineStore" @click="navigateTo()" class="button" target="_blank" style="color:white;" v-html="$t('manual.buy')"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--middle and bot content-->
            <div class="md-layout txtHelite mt-30">

                <div class="md-layout-item md-size-100">
                    <h3 class="mb-10" v-html="$t('details.product')"></h3>
                    <p v-html="$t('details.productText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.bestProtection')"></h3>
                    <p v-html="$t('details.bestProtectionText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.adjust')"></h3> 
                    <p v-html="$t('details.adjustText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.retro')"></h3> 
                    <p v-html="$t('details.retroText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.comfort')"></h3> 
                    <p v-html="$t('details.comfortText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.usefull')"></h3> 
                    <p v-html="$t('details.usefullText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.recon')"></h3>
                    <p v-html="$t('details.reconText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.sysElect')"></h3> 
                    <p v-html="$t('details.sysElectText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.sysNoCable')"></h3>
                    <p v-html="$t('details.sysNoCableText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.auto')"></h3> 
                    <p v-html="$t('details.autoText')"></p>
                    <h3 class="mt-20 mb-10" v-html="$t('details.ce')"></h3> 
                    <p v-html="$t('details.ceText')"></p>
                </div>
            </div>

            <!--Table content-->
            <div class="md-layout">
                <div class="md-layout-item md-size-100 txtHelite">
                    <h3 class="mt-20 mb-10" v-html="$t('tab.title')"></h3>
                    <p v-html="$t('tab.text')"></p>
                </div>
                <div class="md-layout-item md-size-100"> 
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-left">
                        <strong><a href="/#dimension">{{$t('tab.text2')}}</a></strong>
                    </div>
                </div>
            </div>

            <!--import video content-->
            <div class="md-layout">
                <div class="md-layout-item md-size-100 txtHelite">
                    <h3 class=" mt-30 mb-30">Vidéo</h3>
                </div>
                <div class="md-layout-item md-size-100 center" > 
                    <iframe frameborder="0" src="Resources/Video/e-turtle-fr.mp4" style="width:620px; height:350px;"></iframe>
                </div>
            </div>
        </div>
        
    </md-content>
</template>
<i18n src="../../assets/I18N/Screen.Global.json"></i18n>
<i18n src="../../assets/I18N/products/Turtle_hi_vis.json"></i18n>

<style scoped>

    .price { font-size: 20px; font-weight: 600 !important; }
    .md-price { color:#2fa0c2;}
    .md-discount { color: rgb(212, 44, 44);}

    .md-list-item-default {
        margin-top: -20px;
    }
    .tableModif {
        background-color: #017CBD;
        color:#FFFFFF;
        font-size: 11px;
    }
    tbody {
        text-align: left;
        color: #333333;
    }
    td {
        border-bottom: 1px solid #CCC;
        
    }
    .tableHeadSpace {
        padding: 10px;
        height: 56px;
    }
    .button {width:150px ;}
    a.button:hover {background-color:#999999;}

</style>
<script>
    import InnerImageZoom from 'vue-inner-image-zoom';
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

export default {
    name: 'Turtle_hi_vis', 
    mixins: [apiECommerceExtension],
    data: () => ({
        isOnlineStore: $App.onlineStore,
        Product: null,
    }),
    mounted: async function () {
        this.Product = await this.EcommerceProductGetById(1751);
    },
    methods:{
        changeImage: function(imageSrc,imageZoomed){
            this.$refs.principalIMG.src = imageSrc;
            this.$refs.principalIMG.zoomSrc = imageZoomed;
        },
        //En ce moment, c<est celui-co que j'utilise
        navigateTo: async function () { 
            await this.AddItem(this.Product);
            this.$router.push('/order');
        },

    },

        components: {
            'inner-image-zoom': InnerImageZoom
        }
}

</script>