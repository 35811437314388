<template>
    <div>
        <div style="min-height:540px; overflow: hidden;" class="paidBox">
            <div class="backgroundPaid"></div>
            <div class="md-layout mt-30">

                <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
                    <div class="md-layout-item md-size-100 default-pad">
                        <h1 style="padding: 0px 0px 20px 0px;">{{ $t("paid.title") }}</h1>
                    </div>

                    <!--Info Status-->
                    <!--Pending-->
                    <div v-if="status == 'Pending'" class="md-layout-item md-size-75 md-small-size-100 default-pad mt-100">
                        <h2 class="mb-10">{{ $t("paid.treatment") }}</h2>
                        <md-progress-bar md-mode="indeterminate" style="height:10px; width:100%;"></md-progress-bar>
                    </div>

                    <!--Succeed-->
                    <div v-if="status == 'Succeed'" class="md-layout-item md-size-75 md-small-size-100 default-pad">
                        <div class="md-layout-item md-size-100">
                            <h2 class="mb-10 txt-l default-pad txt-j" style="line-height: 35px !important;">{{ $t("paid.finalized") }}</h2>
                        </div>
                    </div>

                    <!--Failed-->
                    <div v-if="status == 'Failed'" class="md-layout-item md-size-75 md-small-size-100 default-pad">
                       <div class="md-layout-item md-size-100" style="line-height: 35px !important;">
                           <h2 class="mb-10 txt-l default-pad txt-j mb-15" v-html="$t('paid.problem')" ></h2>
                           <!-- <h2 class="mb-10 txt-l default-pad txt-j">Si le problème persiste veuillez communique avec un administrateur.</h2> -->
                       </div>

                        <div class="md-layout-item md-size-100 mt-70">
                            <md-button class="mt-5 orderBtn">
                                <div>
                                    <h2 @click="notImplemented()" class="orderBtnTitle">{{ $t("paid.tryAgain") }}</h2>
                                </div>               
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<i18n src="../../../assets/I18N/eCommerce/invoice.json"></i18n>

<style scoped>
    .paidBox {min-height:535px;}
    .orderBtn{ padding: 0px 20px !important; background-color: white; border: 1px solid white; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);}
    .orderBtn:hover {background-color: rgba(var(--secondary),0.9); border: 1px solid rgb(var(--secondary)); box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);}
    .orderBtnTitle {color: rgb(var(--secondary));}
    .orderBtnTitle:hover {color: rgb(var(--tierce));}
    .backgroundPaid {
        display: block;
        position: absolute;
        height: 100%;
        width:450px;
        background-image: url("../../../../public/Resources/Images/invoice/background1.png"); 
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: 100%; 
        right: 0%;
        bottom: 15px;
        z-index: -1;
        opacity:0.5;
        overflow: hidden;
    }
    @media handheld, only screen and (max-width: 960px) {
        .backgroundPaid {
        height: 100%;
        width:450px;
        right: 8%;
        bottom: 15px;
        visibility: hidden;
        }
    }

</style>

<script>
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';


export default {
    name: 'Paid', 
    mixins: [apiECommerceExtension],
    props: {},
    data: () => ({
        status:'Pending', //Succeed, Failed, Pending
        tried: 0,
        confirmed: false,
        param: null,
        exec: {paymentId: '', payerId: ''},
    }),
    watch: {},
    computed: {},
    mounted: async function () {
        this.param = this.$route.params.sid;
        const urlParams = new URLSearchParams(window.location.search);
        this.exec.paymentId = urlParams.get('paymentId');
        this.exec.payerId = urlParams.get('PayerID');
        if(this.exec.paymentId && this.exec.payerId){this.transactionCompleted();} else{ this.status = 'Failed'; }
    },
    methods:{
        navigateToInvoice: async function () { await this.ClearInvoice(); this.$router.push('/invoices/' + this.param); },
        transactionCompleted: async function() {
            
            if(this.exec.paymentId && this.exec.payerId){
                this.tried++;
                let confirmed = await this.PaypalPaided(this.param,this.exec);
                if(confirmed){this.status = 'Succeed'; this.navigateToInvoice();}
                else{
                    if(this.tried < 2){this.transactionCompleted()} else {this.status = 'Failed';}
                }
            } else { this.status = 'Failed';}
        },
        notImplemented: function() {
                //$App.event.$emit("app_notify", { message: this.$t("notImplemented") });
                $App.event.$emit("app_notify", { message: 'not implemented' });
            }

    },
    components: {
    }
}

</script>