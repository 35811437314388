import { render, staticRenderFns } from "./legal-notice.vue?vue&type=template&id=0f6f2174&scoped=true&"
import script from "./legal-notice.vue?vue&type=script&lang=js&"
export * from "./legal-notice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f6f2174",
  null
  
)

/* custom blocks */
import block0 from "../assets/I18N/Screen.Global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Ccomponents%5Clegal-notice.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports