import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import onlineStore from '../views/onlineStore.vue'
//import Event from '../views/Event.vue'

import Electronic from '../views/airbag/Electronic.vue'
import Mechanical from '../views/airbag/Mechanical.vue'

import Policy from '../views/Policy.vue'

import products from '../views/products/Index.vue'

import AirnestNoir from '../views/products/AirnestNoir.vue'
import Gp_air from '../views/products/Gp_air.vue'
import Gp_air2 from '../views/products/Gp_air2.vue'
import Roadster_noir from '../views/products/Roadster_noir.vue'
import Roadster_vintage from '../views/products/Roadster_vintage.vue'
import Touring_grise from '../views/products/Touring_grise.vue'
import Touring_noire from '../views/products/Touring_noire.vue'
import Turtle_hi_vis from '../views/products/Turtle_hi_vis.vue'
import Turtle_hi_vis2 from '../views/products/Turtle_hi_vis2.vue'
import Turtle_noir from '../views/products/Turtle_noir.vue'
import Turtle_noir2 from '../views/products/Turtle_noir2.vue'
import Vented from '../views/products/Vented.vue'

import Faq from '../views/FAQ.vue'
import Contact from '../views/Contact.vue'
import product from '../views/Product.vue'
import order from '../views/eCommerce/Order/order.vue'
import order_shipping from '../views/eCommerce/Order/order-shipping.vue'
import order_summary from '../views/eCommerce/Order/order-summary.vue'
import order_billing from '../views/eCommerce/Order/order-billing.vue'
import invoice from '../views/eCommerce/invoice.vue'
import paypal_paid from '../views/eCommerce/Paypal/paid.vue'
import paypal_canceled from '../views/eCommerce/Paypal/canceled.vue'


Vue.use(VueRouter)

  const routes = [
    {
      path: '*',
      name: 'Home',
      component: Home
    },
    {
      path: '/',
      name: 'Home1',
      component: Home
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/airbag/electronic',
      name: 'Electronic',
      component: Electronic
    },
    {
      path: '/airbag/mechanical',
      name: 'Mechanical',
      component: Mechanical
    },
    
    {
      path: '/products',
      name: 'products',
      component: products
    },
    {
      path: '/airnestNoir',
      name: 'AirnestNoir',
      component: AirnestNoir
    },
    {
      path: '/gp_air',
      name: 'Gp_air',
      component: Gp_air
    },
    {
      path: '/gp_air2',
      name: 'Gp_air2',
      component: Gp_air2
    },
    {
      path: '/roadster_noir',
      name: 'Roadster_noir',
      component: Roadster_noir
    },
    {
      path: '/roadster_vintage',
      name: 'Roadster_vintage',
      component: Roadster_vintage
    },
    {
      path: '/touring_grise',
      name: 'Touring_grise',
      component: Touring_grise
    },
    {
      path: '/touring_noire',
      name: 'Touring_noire',
      component: Touring_noire
    },
    {
      path: '/turtle_hi_vis',
      name: 'Turtle_hi_vis',
      component: Turtle_hi_vis
    },
    {
      path: '/turtle_hi_vis2',
      name: 'Turtle_hi_vis2',
      component: Turtle_hi_vis2
    },
    {
      path: '/turtle_noir',
      name: 'Turtle_noir',
      component: Turtle_noir
    },
    {
      path: '/turtle_noir2',
      name: 'Turtle_noir2',
      component: Turtle_noir2
    },
    {
      path: '/vented',
      name: 'Vented',
      component: Vented
    },
    {
      path: '/PolitiqueDeConfidentialiter',
      name: 'Policy',
      component: Policy
    },
    {
      path: '/product/:id',
      name: 'Product',
      component: product
    },
    {
      path: '/onlineStore',
      name: 'onlineStore',
      component: onlineStore
    },
    {
      path: '/onlineStore/:id',
      name: 'onlineStoreId',
      component: onlineStore
    },
    //{
    //  path: '/event',
    //  name: 'Event',
    //  component: Event
    //},
    {
      path: '/order',
      name: 'order',
      component: order
    },
    {
      path: '/order/shipping',
      name: 'order_shipping',
      component: order_shipping
    },
    {
      path: '/order/summary',
      name: 'order_summary',
      component: order_summary
    },
    {
      path: '/order/billing',
      name: 'order_billing',
      component: order_billing
    },
    {
      path: '/invoice',
      name: 'invoice',
      component: invoice
    },
    {
      path: '/invoices/:id',
      name: 'invoice_ID',
      component: invoice
    },
    {
      path: '/invoices/:sid/paypal/paid',
      name: 'paypal_paid',
      component: paypal_paid
    },
    {
      path: '/invoices/:sid/paypal/canceled',
      name: 'paypal_canceled',
      component: paypal_canceled
    },

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }, 
  base: process.env.BASE_URL,
  routes
})

export default router
