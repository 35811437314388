import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import Vuelidate from 'vuelidate'
import VueCarousel from 'vue-carousel';
import application from './../public/Resources/Scripts/app.js'

import VueI18n from 'vue-i18n'

import $ from 'jquery'
/*import * as filters from './filters.js'*/

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import '../public/Resources/css/vue-material-extension.css'
import '../public/Resources/css/app.css'

import directives from './directives.js'
directives.registerAll();

import filters from './filters.js'
filters.registerAll();

import $event from './event.js'

if(!$App.event){ $App.event = $event; }

Vue.config.productionTip = false
Vue.config.lang = 'fr';
Vue.config.fallbackLang = 'fr';
Vue.use(VueMaterial);
Vue.use(Vuelidate);

Vue.use(VueI18n)
Vue.use(VueCarousel);



// Ready translated locale messages
const messages = {
  en: {
    message: {
      hello: 'hello world',
      welcome: 'Welcome to Your Vue.js App',
      about: 'This is an about page'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour le monde',
      welcome: 'Bienvenue dans votre application Vue.js',
      about: 'Voici la page a propos'
    }
  }
}

// Create VueI18n instance with options
var culture = localStorage.getItem("Culture");

const i18n = new VueI18n({
  locale: culture, // set locale
  fallbackLocale : 'fr',
  allowComposition: true,
  messages, // set locale messages
})


router.beforeEach((to, from, next) => {
  
  /*
  if ( !['en','fr'].includes(lang) ) {
    let url = '/' + i18n.locale + to.fullPath
   //  console.log(url);
    return next(url);
  }*/

  // const lang = to.params.lang;
  // if ( i18n.locale !== lang ) { i18n.locale = lang; }

  return next();
})


new Vue({
  router,
  i18n,
  application,
  render: function (h) { return h(App) }
}).$mount('#app')

