<template>
    <div class="header default-pad">
        <div class="md-layout">

            <div class="md-layout-item md-size-30 md-layout ">
                <router-link to="/home" >
                    <img src="../../public/Resources/Images/logo.png" alt="logo compagnie helite" title="logo compagnie helite"/>
                </router-link>
            </div>

            <div class="md-layout-item md-layout t-r">

                <div class="md-layout-item md-layout md-size-100 md-alignment-center md-headline pr-10 divHeight50 ">
                    <div class="md-layout-item">
                        <a class="lang" v-on:click="setCulture('en')" :class="{'langActive': $i18n.locale == 'en'}" >EN</a> | <a class="lang" v-on:click="setCulture('fr')" :class="{'langActive': $i18n.locale =='fr'}">FR</a>
                        <a v-if="this.invoice" class="orderInvoice" :href="'/invoice'" style="position: relative;"></a>
                        <a v-if="this.orderLength > 0" class="shopIcon" href="/order">
                            <span class="material-icons">shopping_cart</span>
                            <div class="lengthCircle">{{this.orderLength}}</div>                           
                        </a>                        
                        
                    </div>
                </div>
                <div class="md-layout-item md-size-100 ">

                    <!--Home-->
                    <md-menu class="md-xsmall-hide">
                        <md-button :md-ripple="false">
                            <router-link to="/home" class="md-headline" v-html="$t('header.home')"></router-link>
                        </md-button>
                    </md-menu>
                    <!--Home-->
                    <md-menu v-if="isOnlineStore" class="md-xsmall-hide">
                        <md-button :md-ripple="false">
                            <router-link to="/onlineStore" class="md-headline" v-html="$t('header.shop')"></router-link>
                        </md-button>
                    </md-menu>
                
                    <!--Products-->
                    <md-menu class="md-xsmall-hide" md-align-trigger md-size="big" :md-offset-x="-10">
                        <md-button md-menu-trigger :md-ripple="false">
                            <!-- <a class="md-headline" v-html="$t('header.products.title')"></a> -->
                            <router-link to="/products" class="md-headline" v-html="$t('header.products.title')"></router-link>
                        </md-button>
                        
                    </md-menu>

                    <!--FAQ-->
                    <md-menu class="md-xsmall-hide">
                        <md-button :md-ripple="false">
                            <router-link to="/faq" class="md-headline">F.A.Q</router-link>
                        </md-button>
                    </md-menu>

                    <!--Airbag-->
                    <md-menu class="md-xsmall-hide" md-align-trigger md-size="huge" :md-offset-x="-10">
                        <md-button md-menu-trigger :md-ripple="false">
                            <a class="md-headline">AIRBAG</a>
                        </md-button>
                        <md-menu-content class="md-layout-item md-layout">
                            <md-list class="md-layout-item md-layout">
                                <div class="md-layout-item">
                                    <router-link to="/airbag/mechanical" v-html="$t('header.airbag.mechanical')"></router-link><br/>
                                    <router-link to="/airbag/electronic" v-html="$t('header.airbag.electronic')"></router-link><br/>
                                </div>
                            </md-list>
                        </md-menu-content>
                    </md-menu>

                    <!--Contact-->
                    <md-menu class="md-xsmall-hide">
                        <md-button :md-ripple="false">
                            <router-link to="/contact" class="md-headline">CONTACT</router-link>
                        </md-button>
                    </md-menu>   
                    
                    <!--Responsive Button-->
                    <md-menu class="displayMobile" md-align-trigger :md-offset-y="0">

                        <md-button md-menu-trigger :md-ripple="false">
                            <md-icon class="menuIcon">menu</md-icon>
                        </md-button>

                        <!--max-height pour enlever la scrollbar-->
                        <md-menu-content class="menuMobile">

                                  <md-list style="padding:0px;">
                                    <md-list-item class="md-layout">
                                        <router-link to="/home" class="md-headline white md-layout plx35 " v-html="$t('header.home')"></router-link>
                                    </md-list-item>

                                    <md-list-item v-if="isOnlineStore">
                                      <router-link to="/onlineStore" class="md-headline white plx35" v-html="$t('header.shop')"></router-link>
                                    </md-list-item>

                                    <md-list-item>
                                        <router-link to="/products" class="md-headline white plx35" v-html="$t('header.products.title')"></router-link>
                                    </md-list-item>
  
                                    <md-list-item>
                                      <router-link to="/faq" class="md-headline white plx35">F.A.Q</router-link>
                                    </md-list-item>

                                    <md-list-item md-expand>
                                        <a class=" md-headline white plx35">AIRBAG</a>

                                        <md-list slot="md-expand">
                                            <md-list-item class=""><router-link to="/airbag/mechanical"><h7 class="pl-120" v-html="$t('header.airbag.mechanical')"></h7></router-link></md-list-item>
                                            <md-list-item class=""><router-link to="/airbag/electronic"><h7 class="pl-120" v-html="$t('header.airbag.electronic')"></h7></router-link></md-list-item>
                                            <!-- <md-list-item class=""><router-link to="/system"><h7 class="pl-120" v-html="$t('header.airbag.sys')"></h7></router-link></md-list-item>
                                            <md-list-item class=""><router-link to="/protection"><h7 class="pl-120" v-html="$t('header.airbag.protect')"></h7></router-link></md-list-item>
                                            <md-list-item class=""><router-link to="/techTurtle"><h7 class="pl-120" v-html="$t('header.airbag.tech')"></h7></router-link></md-list-item> -->
                                        </md-list>
                                    </md-list-item>

                                    <md-list-item>
                                            <router-link to="/contact" class="md-headline white plx35">CONTACT</router-link>
                                    </md-list-item>
                                </md-list>
                              

                        </md-menu-content>
                    </md-menu> 

                </div>
                    
            </div>
        </div>
    </div>
</template>
<style scoped>

    .divHeight50 { height: 50px;}
    .header { height:100px; background-color: #01669C; width: 100%; position: sticky; top: 0; z-index: 10000;}
    .header img {padding-top: 15px; height: auto;} 

    .md-transparent { background-color: transparent !important;}

    .md-menu .md-button { min-width: unset !important; }
    .md-menu .md-button:hover { background-color: transparent !important; }
    .md-menu .md-button a {color: white;}
    .md-menu .md-button a:hover { text-decoration:none;color:#2fa0c2; background-color: none !important; }
    .md-list .md-button a {color: #fff;}
    .md-list .md-button a:hover {text-decoration:none; color: #2fa0c2;}

    .lang {color: black !important; cursor: pointer;}
    .langActive {text-decoration:underline; color:#2fa0c2 !important;}
    .lang:hover {text-decoration:underline; color:#2fa0c2 !important;}
    .shopIcon {color: black !important;}
    .shopIcon:hover {text-decoration: underline ;color: #2fa0c2 !important;}
    .menuIcon {color: #fff !important; }
    .menuIcon:hover {text-decoration:none ;color: #2fa0c2 !important;}
    .md-list .md-theme-default{ margin: -8px 0px; padding-left: 20px; }
    .md-list {background-color: #01669C !important; text-align: start !important;}
    .md-list .md-layout-item a {color: white; line-height:40px; font-size:18px;}
    .md-list .md-layout-item a:hover {color:#2fa0c2; text-decoration:none;}

    .white{ color:#fff !important; font-size: 16px; }
    .white:hover {color:#2fa0c2 !important; text-decoration: none;}
    #mobileMenu .md-menu-content{
        /*min-width: 100%;*/
        left: 0px !important;
        min-width:100%;
        max-height: 100%;
        margin-left: 13px !important;
    }
    .menuMobile { left: 0px !important; min-width:100%; max-height: 100%; }
    a.md-headline { font-size: 24px; }
    .displayMobile {display:none;}
    .lengthCircle{ position: relative;  float: right; font-size: 11px; transform: translate(-40%,40%); color: white; text-align: center; height: 14px; width: 14px; border-radius: 50%;}
    .orderInvoice{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resources/Images/invoice.svg"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
    .orderInvoice:hover{background-image: url("../../public/Resources/Images/invoiceBlack.svg");}
    @media handheld, only screen and (max-width: 1350px)  {
        .md-theme-default{
            margin: 0px 0px;
            padding-left: 0px;
            padding-bottom: 0px;
            /* width: 100%; */
        }
        a.md-headline { font-size: 22px; }
        .md-menu.md-menu-content.md-theme-default{ background-color: #01669C !important; }
        .default-pad {padding: 0px 30px 0px 50px;}
    }
    @media handheld, only screen and (max-width: 1100px)  {
        .default-pad {padding: 0px 20px 0px 20px;}
        a.md-headline { font-size: 18px; }
}
@media handheld, only screen and (max-width: 900px)  {
        a.md-headline { font-size: 16px; }
        .header img { max-width: 150px;} 
}
@media handheld, only screen and (max-width: 800px)  {
        a.md-headline { font-size: 12px; }
}

    @media handheld, only screen and (max-width: 600px)  {
    .displayMobile {display:block;}
}
</style>
<i18n src="../assets/I18N/Screen.Global.json"></i18n>
<script>
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
    
    export default {
        name: 'content-header',
        mixins: [apiECommerceExtension],
        data: () => ({
            isOnlineStore: $App.onlineStore,
            showNavigation: false,
            showSidepanel: false,
            orderLength: 0,
            invoice: '',
        }),
        computed:{
        },
        mounted: async function () {

            this.getCulture();

            this.orderLength = 0;
            this.orderLength = this.isOrder();
            this.invoice = this.GetInvoiceRef();
        },
        methods: {
        getCulture: function() {
            let culture = localStorage.getItem("Culture");
            if(culture) { this.$i18n.locale = culture; }
            else { 
                this.setCulture("fr");
            }
        },
        setCulture: function(e) {
            if(e) {
                this.$i18n.locale = e;
                localStorage.setItem('Culture', this.$i18n.locale);
                window.location.reload(); 
            }
        },
        },        
        component: {
        }
    }
</script>