<template>
    <div v-if="ready && current" class="md-content">

        <div v-if="mode != 'edit'">
            <div v-if="title" class="md-layout md-layout-item md-size-90">
                <span class="md-title">{{title}}&nbsp;</span><span v-if="current.type" class="md-my-auto">({{current.type.title}})</span>
                <md-button class="md-icon-button md-dense md-my-auto ">
                    <md-icon>info</md-icon><md-tooltip md-direction="right">{{current.description}}</md-tooltip>
                </md-button>
            </div>
            <label>{{current.number | phone}}</label><label v-if="current.extension > 0"> #{{current.extension}}</label>
        </div>

        <div class="" v-if="mode == 'edit'">
            <!-- RL not remove next div with v-if (use for binding)... -->
            <!-- <div v-if="ready"></div> -->
            <span v-if="title" class="md-title">{{title}}</span>
            <div class="md-layout">
                <md-field class="md-layout-item  md-mr-3 " > <!-- :class="$v.current.number|validations"-->
                    <label for="txtNumber">{{ $t("phone") }} : <span class="md-medium-hide ">xxx-xxx-xxxx</span> </label>
                    <md-input @change="onChange" name="txtNumber" v-format.phone="current.number" :readonly="isReadOnly" :required="isRequired" />
                </md-field>
                <md-field class="md-layout-item md-size-20" > <!-- :class="$v.current.extension|validations"-->
                    <label for="txtExtension">Extension</label>
                    <md-input name="txtExtension" v-model.number="current.extension" type="text" />
                </md-field>
                <div v-if="includeType != false" class="md-layout-item md-small-size-100">
                    <field-type  title="Type" v-bind:item.sync="current" @md-selected="onTypeSelected" property="typeId" :defaultValue="null" sourceURI="PhoneTypes" :expand="false" :readonly="isReadOnly" />
                </div>
            </div>
            <field-desc v-if="includeDescription != false" title="Description" v-bind:item.sync="current" property="description" :readonly="isReadOnly" />
        </div>
    </div>
</template>
<i18n>
    {
      "en": {
        "phone": "Phone"
      },
      "fr": {
        "phone": "Téléphone"
      }
    }
  </i18n>
<style scoped>
    label {
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
    }     
</style>
<script>
    import { required, requiredIf, requiredUnless, between, numeric } from 'vuelidate/lib/validators'
    
    import field_type from '@/components/Commons/Fields/field-select-wd.vue';
    import field_desc from '@/components/Commons/Fields/field-textarea-c.vue';

    export default {
        name: 'phone',
        props: {
            mode: String,
            title: String,
            item: Object,
            includeDescription: Boolean,
            includeType: Boolean,
            readonly: Boolean,
            required: Boolean
        },

        mixins: [],
        data: () => ({
            current: {},
        }),
        computed: {
            ready: function () {
                let val = false;
                if (this.item) {
                    // RL binding issue
                    this.$nextTick(() => {
                        this.current = this.item;
                    });
                        val = true;
                    }                
                return val;
            },
            isReadOnly: function() {
                return this.readonly;
            },
            isRequired: function() {
                return this.required;
            }

        },
        validations: function() {
            if(this.isRequired) {
                return {
                    current: { 
                        number: { required, between: between(999999999, 10000000000) },
                        extension: { numeric }
                    }
                }
            } else {
                return { current: { number: {}, extension: {} } }
            }
        },        
        mounted: async function () {

            this.current = this.item;
        },
        methods: {
            onTypeSelected: function (e) {
                this.current.type = e;
            },
            onChange: function() {
                this.$emit("change");
            }            
        },
        components: {
            'field-type': field_type,
            'field-desc': field_desc,
        }
    }
</script>