import { render, staticRenderFns } from "./Turtle_noir2.vue?vue&type=template&id=9eae6b46&scoped=true&"
import script from "./Turtle_noir2.vue?vue&type=script&lang=js&"
export * from "./Turtle_noir2.vue?vue&type=script&lang=js&"
import style0 from "./Turtle_noir2.vue?vue&type=style&index=0&id=9eae6b46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9eae6b46",
  null
  
)

/* custom blocks */
import block0 from "../../assets/I18N/Screen.Global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5Cproducts%5CTurtle_noir2.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "../../assets/I18N/products/Turtle_noir2.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5Cproducts%5CTurtle_noir2.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports