<template>
    <md-content>
            <div class="default-pad">
                <div class="md-layout-item md-size-100 mt-50">
                  <h3 class="pb-10" v-html="$t('page.title')"></h3>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand>

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question1')"></md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p class="mb-20" v-html="$t('page.reponse1')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question2')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse2')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question3')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse3')"></p>
                          <div class="ml-25 mt-20">
                          <md-list class="triangle">
                            <md-list-item>
                              <span v-html="$t('page.reponse3Ol1')"></span>
                            </md-list-item>
                            <md-list-item>
                              <span v-html="$t('page.reponse3Ol2')"></span>
                            </md-list-item>
                            <md-list-item>
                              <span v-html="$t('page.reponse3Ol3')"></span>
                            </md-list-item>
                            <md-list-item>
                              <span v-html="$t('page.reponse3Ol4')"></span>
                            </md-list-item>
   			                  </md-list>
                          </div>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question4')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse4')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question5')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse5')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question6')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse6')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question7')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse7')"></p> 
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand >

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question8')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse8')"></p>
                        </md-card-content>
                      </md-card-expand-content>

                    </md-card-expand>
                  </md-card>
                </div>
                <div class="card-expansion mt-25">
                  <md-card>
                    <md-card-expand>

                      <md-card-actions class="md-primary" md-alignment="left">
                        <md-card-expand-trigger>
                          <md-button class="white" v-html="$t('page.question9')"></md-button>
                          <md-button class="md-icon-button">
                            <md-icon>keyboard_arrow_down</md-icon>
                          </md-button>
                        </md-card-expand-trigger>
                      </md-card-actions>

                      <md-card-expand-content>
                        <md-card-content>
                          <p v-html="$t('page.reponse9')"></p>
                          <p><a class="mailFAQ" href="mailto:helite-canada@helite.ca">helite-canada@helite.ca</a></p>
                        </md-card-content>
                      </md-card-expand-content>
                      
                    </md-card-expand>
                  </md-card>
                </div>
            </div>
            
    </md-content>
</template>
<i18n src="../assets/I18N/FAQ.json"></i18n>

<style scoped>
    .white{
        color:#fff !important;
        font-size: 16px;
        font-weight: bold;
    }
    p {
        text-align: left;
        font-size: 16px;
    }
    .red {
        color: red;
        font-weight: bold;
    }
    .md-list-item-default {
        margin-top: -20px;
    }

</style>
<script>
    //import pageTranslations from '../../src/assets/I18N/FAQ.json';
export default {
    name: 'Faq', 
    data: () => ({
        
    }),
    mounted: async function () {
      //this.$i18n.mergeLocaleMessage('fr', pageTranslations.fr);
      //this.$i18n.mergeLocaleMessage('en', pageTranslations.en);

    },

  

        components: {
        }
}

</script>