<template>
  <div>
    <div class="default-pad" style="min-height:550px;"> 
      <div class="md-layout mt-30">

        <!--Left side Box-->
        <div class="md-layout-item md-layout md-size-75 md-small-size-100">
          
          <!--Top Title-->
          <div class="md-layout-item md-layout">

            <div class="md-layout-item md-size-100">
              <h1>{{ $t("summary.title") }}</h1>
            </div>
            <div class="md-layout-item md-layout md-size-100">
              <h2 class="md-pl-3 md-pt-2">{{ $t("summary.slogan") }}</h2>
            </div>
  
          </div>

          <!-- Item section-->
          <div v-if="order" class="md-layout-item md-size-100 md-mt-3">
            
            <div class="md-layout-item md-layout md-size-100 md-mt-5">
              <div v-if="this.order.items && this.order.items.length > 0" class="md-layout-item md-layout md-size-100">
                <div class="md-layout-item md-size-100"><h1 >{{ $t("general.items") }}</h1></div>
              </div>
              <div v-else class="md-layout-item md-layout">
                <div class="md-layout-item md-layout md-size-100"><h2>{{ $t("general.noItems") }}</h2></div>
              </div>
            </div>

            <div class="md-layout-item md-layout md-size-100 md-mt-3 md-pr-5">
              
              <div v-if="this.order.items && this.order.items.length > 0" class="md-layout-item md-layout md-size-100 md-xsmall-hide">
                <div class="md-layout-item md-layout md-size-25 md-xsmall-size-100 md-alignment-center">&nbsp;</div>
                <div class="md-layout-item md-layout md-size-35 md-small-size-30 md-xsmall-size-100"><h2 class="mobileText md-layout md-alignment-center-left">{{ $t("general.name") }}</h2></div>
                <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.price") }}</h2></div>
                <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.discount") }}</h2></div>
                <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.qty") }}</h2></div>
                <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.total") }}</h2></div>
              </div>

              <div v-if="this.order.items && this.order.items.length > 0" class="md-layout-item md-layout md-size-100">
                <div v-for="(item, i) in this.order.items" :key="i" class="md-layout-item md-layout md-size-100 md-my-3">

                  <div class="ItemsBox md-layout md-size-100">

                    <div class="md-layout-item md-layout md-size-25 md-xsmall-size-100 md-alignment-center">
                      <div class="ItemsImgBox"><img v-if="item.url"  class="ItemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="ItemsImg" :src="this.image" :truesrc="this.image"/></div>
                    </div>
                    <div class="md-layout-item md-layout md-size-35 md-small-size-30 md-xsmall-size-100 md-alignment-center-left"><h2 class="mobileText">{{item.description}}<br />{{item.options}}</h2></div>
                    <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{item.price}}$</h2></div>
                    <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center md-discount"><h2 class="mobileText">{{item.discount}}$</h2></div>
                    <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{item.qty}}</h2></div>
                    <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{item.total}}$</h2></div>
  
                  </div>
                
                </div>
                <div class="md-layout-item md-layout md-size-100">{{ $t("shipping.deliveryDelay") }}</div>
              </div>


            </div>
          </div>          

          <!--Info section-->
          <div v-if="order" class="md-layout-item md-layout md-size-100">
            
            <div class="md-layout-item md-size-100 md-mt-3">
              <h1 class="md-pl-2">{{ $t("general.info") }}</h1>
            </div>

            <div class="md-layout-item md-layout md-size-100 md-mt-3">
              <div class="md-layout-item md-layout md-small-size-100 md-px-5">

                <div class="md-layout-item md-size-100 md-layout">
                  <div class="md-layout-item md-layout md-alignment-top-left">
                      <span class="md-subheading" >{{ $t("general.name") }}:</span>
                    </div>
                  <div class="md-layout-item md-layout md-alignment-top-right">
                    <label v-if="order.account.name" >{{order.account.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-layout">
                  <div class="md-layout-item md-layout md-alignment-top-left">
                      <span class="md-subheading" >{{ $t("general.phone") }}:</span>
                    </div>
                  <div class="md-layout-item md-layout md-alignment-top-right">
                    <field-phone ax-id="orderSummaryPhone" class="f-secondary t-r" mode="view" v-bind:item.sync="order.account.phone" :required="false" :includeType="false" :readonly="true"  />
                  </div>
                </div>
                <div class="md-layout-item md-size-100 md-layout">
                  <div class="md-layout-item md-layout md-alignment-top-left">
                      <span class="md-subheading" >{{ $t("general.email") }}:</span>
                    </div>
                  <div class="md-layout-item md-layout md-alignment-top-right">
                    <label v-if="order.account.email.address" >{{order.account.email.address}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                  </div>
                </div>                              

              </div>
              <div class="md-layout-item md-layout md-small-size-100 md-px-5">&nbsp;</div>
            </div>

            <!--Billing Info-->
            <div class="md-layout-item md-layout md-size-50 md-small-size-100 md-mt-3">
              <div class="md-layout-item md-layout md-size-100 md-px-3">
                <h3 class="f-primary" style="padding: 20px 0px 20px 0px;">{{ $t("summary.billedTo") }}</h3>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.address") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.account.address.street" >{{order.account.address.street}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.city") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.account.address.city.name" >{{order.account.address.city.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.zipCode") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.account.address.zipCode" >{{order.account.address.zipCode}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.state") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.account.address.city.state.name" >{{order.account.address.city.state.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.country") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.account.address.city.state.country.name" >{{order.account.address.city.state.country.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>
            </div>

            <!--Delivery Info-->
            <div class="md-layout-item md-layout md-size-50 md-small-size-100 md-mt-3">
              <div class="md-layout-item md-layout md-size-100 md-px-3">
                <h3 class="f-primary" style="padding: 20px 0px 20px 0px;">{{ $t("summary.deliveredTo") }}</h3>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.address") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.shippingAddress.street" >{{order.shippingAddress.street}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.city") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.shippingAddress.city.name" >{{order.shippingAddress.city.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.zipCode") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.shippingAddress.zipCode" >{{order.shippingAddress.zipCode}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.state") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.shippingAddress.city.state.name" >{{order.shippingAddress.city.state.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>

              <div class="md-layout-item md-size-100 md-layout md-px-5">
                <div class="md-layout-item md-layout md-alignment-top-left">
                    <span class="md-subheading" >{{ $t("summary.country") }}:</span>
                  </div>
                <div class="md-layout-item md-layout md-alignment-top-right">
                  <label v-if="order.shippingAddress.city.state.country.name" >{{order.shippingAddress.city.state.country.name}}</label><label v-else class="error">[{{ $t("summary.toComplete") }}]</label>
                </div>
              </div>              

            </div>

          </div>

          <!--Indication Species-->
          <div class="md-layout-item md-layout md-size-100 md-mt-5">
            <h1 class="md-pl-2">{{ $t("summary.comment") }}</h1>
            <div class="md-layout-item md-layout md-size-100 t-l  md-p-5">
              <span v-if="order" v-html="$options.filters.textWithBR(order.comment)"></span>
            </div>
          </div>

          <!--Legal mention-->
          <div class="md-layout-item md-layout md-size-100 md-mt-3">
            <legal-notice class="md-pl-2"></legal-notice>
          </div>

        </div>

        <!--Order side Box-->
        <div v-if="order" class="md-layout-item md-layout">
          <Summary step="paiement" v-bind:item="order" @eCommerceCompleted="completeOrder" @eCommerceBack="back" />
        </div> 

      </div>
    </div>

  </div>
</template>
<i18n src="../../../assets/I18N/eCommerce/order.json"></i18n>
<i18n>
  {
    "en": {
      "title": "Title1"
    },
    "fr": {
      "title": "Titre1"
    }
  }
</i18n>

<style scoped>
    label {
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
    }
    .md-subheading { font-weight: bold;}

  .md-discount { color: rgba(var(--secondary),0.3); }

  .ItemsImg {height:100%; border-radius: 50%;}
  .ItemsImgBox {height: 110px;}
  .ItemsBox {position:relative; color: rgba(var(--primary));  height: 140px; background-color: rgb(var(--tierce)); box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);}

  .mobileText {text-align: left; padding:5px;}
  
    @media handheld, only screen and (max-width: 600px) {
      .ItemsBox { height: 180px;}
      .default-pad { padding: 0px !important; }
    }
    @media handheld, only screen and (max-width: 958px) {
      .mobileText {margin-left:auto; margin-right:auto; padding: 0px;}
      .default-pad { padding: 0px !important; }
    }
    @media handheld, only screen and (max-width: 1264px) {}
    
</style>

<script>
import Summary from '@/components/infoPayment.vue'
import field_phone from'@/components/Commons/phone.vue'
import legal_notice from'@/components/legal-notice.vue'


import { apiECommerceExtension } from '../../../mixins/apiECommerceExtension';


export default {
  name: 'Order_summary', 
  mixins: [apiECommerceExtension],
  props: {
    //summary: Object,
  },
  data: () => ({
    order: null,
    image: 'https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg',
  }),
  mounted: async function () {

    if(!$App.onlineStore) { this.$router.replace('/'); }

    this.order = await this.GetOrder();
  },
  methods:{
    completeOrder: async function(InvoiceRef) {
      if(InvoiceRef != null){this.navigateTo(InvoiceRef);}else{alert('erreur dans la procedure')}      
    },
    navigateTo: function (InvoiceRef) {
        let cleared = this.ClearOrder();
      if(cleared == true){ this.$router.push('/invoices/' + InvoiceRef);}        
    },
    back: function(){this.$router.push('/order/shipping');},
  },
  components: {
    'Summary': Summary,
    'field-phone' : field_phone,
    'legal-notice' : legal_notice
  }
}

</script>