window.$App = {
    name: "Helite!",
    tenant: "Helite",
    onlineStore: true,
    url: {
        // root: "https:./app.html",
        // login: "https:./login.html",
        api: "https://abstracxion.wx3data.com/",
        root: "http:./app.html",
        login: "http:./login.html",
        // api: "http://localhost:5076/",
        // api: "https://abstracxion_tst.wx3data.com/",
        // api: "http://localhost:17548/"
    },
    event: null,
    getToken: function () {
        let token = sessionStorage.getItem('user-token');

        var d = this.getTokenExpiration(token);

        if (d < new Date()) {
            sessionStorage.removeItem('user-token');
            sessionStorage.removeItem('autorized');
            window.location = $App.url.login;
        }

        if (token == null || token == '') {
            window.location = $App.url.login;
        } else {
            return token;
        }
    },
    isAutorized: function(key){
        try {
                let autorized = sessionStorage.getItem('autorized');
                if (autorized) {
                    var keys = autorized.split(',')
                    var s = keys.find(e => e == key);
                    if (s) { return true; }
                    else { return false; }
                }
                else { return false; }
            } 
            catch (error) {
            console.error(error);
            return false;
          }
    },
    getTokenExpiration: function (token) {
        try {
            decodedToken = this.parseToken(token);
            return new Date(decodedToken.exp * 1000);
        } catch (e) {
            return null;
        }
    },
    parseToken: function (token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    },
    getRangeHeader: function (response) {
        // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Range
        //Content-Range: <unit> <range-start>-<range-end>/<size>
        //Content-Range: <unit> <range-start>-<range-end>/*
        //Range: <unit>=<range-start>-
        //Range: <unit>=<range-start>-<range-end>

        if (response.status != 206) { return null; }

        let range = 'items=0-';
        if (response) {
            let contentRange = response.headers.get('Content-Range');
            if (contentRange) {
                let r = contentRange.split(' ');
                if (r.length > 0) {
                    let r2 = r[1].split('/');
                    if (r2.length > 0) {
                        let r3 = r2[0].split('-');
                        if (r3.length > 0) {
                            let first = parseInt(r3[1]) + 1;
                            let total = parseInt(r2[1]);
                            if (first != total) {
                                range = 'items=' + first + '-';
                            }
                        }
                    }
                }
            }
        }

        return range;
    }    
}; 