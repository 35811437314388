<template>
    <md-content>
        <div class="default-pad">  

            <!--top content-->
            <div class="md-layout txtHelite mt-40">

                <!--top left content-->
                <div class="md-layout-item md-medium-size-30 md-size-30 md-small-size-100xzoom-container center mt-10"> 
				    <inner-image-zoom ref="principalIMG" src="Resources/Images/preview/t21_0_001.jpg" zoomSrc="Resources/Images/original/t21_0_002.jpg" />
                    <div class="xzoom-thumbs">
				        <a @click="changeImage('Resources/Images/preview/t21_0_001.jpg','Resources/Images/original/t21_0_002.jpg')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/t21_0_001.jpg"></a>
					    <a @click="changeImage('Resources/Images/preview/t21_3_001.jpg','Resources/Images/original/t21_3.jpg')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/t21_3_001.jpg"></a>
					    <a @click="changeImage('Resources/Images/preview/t21_7_001.jpg','Resources/Images/original/t21_7.jpg')"><img class="xzoom-gallery" width="80" src="Resources/Images/preview/t21_7_001.jpg"></a>
				    </div>
                </div>

                <!--space between layout content-->
                <div class="md-layout-item md-size-10"></div>

                <!--top right content-->
                <div class="md-layout-item md-size-60" v-if="Product">
                    <div class="md-layout-item md-size-100 mt-10 mb-10">
                        <h3>{{Product.title}}</h3>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <p class="price">
                            <span class="md-price"> {{Product.price}}$</span><span v-if="Product.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{Product.discount}}</span>
                        </p>                            
                        <!-- <h5>$949.99 L-L et XLL</h5> -->
                    </div>
                    <div class="md-layout-item md-size-100 mt-20"> 
                        <p v-html="$options.filters.textWithBR(Product.description)"></p>
                    </div>
                    <div class="md-layout-item md-size-100"> 
                        <div class="md-layout-item md-size-100 mt-60"> 
                            <h3 v-html="$t('manual.title')"></h3>
                        </div>
                        <div class="md-layout-item md-layout">
                            <div class="md-layout-item md-size-30 md-medium-size-40 md-small-size-100 md-xsmall-size-100 mt-15">
                                <a href="Resources/Documents/user-guide-moto-helite.pdf" target="_blank">
                                    <img class="border_img" src="Resources/Images/user_guide.jpg"></a>
                            </div>

                                <!-- md-xlarge-size-30 md-large-size-30 md-medium-size-40 md-small-size-40 mt-15 -->
                                <div class="md-layout-item md-size-40 txtproHelite ml-40">
                                <p v-html="$t('manual.text')" class="pb-25"></p>
                                <a href="Resources/Documents/user-guide-moto-helite.pdf" class="button" target="_blank" style="color:white;" v-html="$t('manual.download')"></a>
                                <a v-if="isOnlineStore" @click="navigateTo()" class="button" target="_blank" style="color:white;" v-html="$t('manual.buy')"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--middle and bot content-->
            <div class="md-layout txtHelite mt-20">

                <div class="md-layout-item md-size-100">
                    <h3 class="mb-10" v-html="$t('details.protect')"></h3>
                    <div class="ml-25">
                        <md-list class="triangle">
                            <md-list-item>
                                <span v-html="$t('details.protectText')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.protectText2')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.protectText3')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.protectText4')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.protectText5')"></span>
                            </md-list-item>
   			            </md-list>
                    </div>
                </div>
                <div class="md-layout-item md-size-100">
                    <h3 class="mb-10" v-html="$t('details.comfort')"></h3>
                    <div class="ml-25">
                        <md-list class="triangle">
                            <md-list-item>
                                <span v-html="$t('details.comfortText')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.comfortText2')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.comfortText3')"></span>
                            </md-list-item>
                            <md-list-item>
                                <span v-html="$t('details.comfortText4')"></span>
                            </md-list-item>
                        </md-list>
                    </div>
                </div>
            </div>
            
             <!--Table content-->
            <div class="md-layout">
                <div class="md-layout-item md-size-100 txtHelite">
                    <h3 class="mt-20 mb-10" v-html="$t('tab.title')"></h3>
                    <p v-html="$t('tab.text')"></p>
                </div>
                <!--tables-->
                <div class="md-layout-item md-size-100"> 
                    <div class="md-layout-item md-size-100 md-layout md-alignment-center-left">
                        <strong><a href="/#dimension">{{$t('tab.text2')}}</a></strong>
                    </div>
                </div>
            </div>

            <!--Technology content-->
            <div class="md-layout"> 
                <div class="md-layout-item md-size-100 txtHelite mt-30 mb-30">
                    <h3 v-html="$t('tab.tech')"></h3>
                </div>
                <div class="md-layout-item md-size-100 imgtech" > 
                    <img src="Resources/Images/ce.jpg" />
                    <img src="Resources/Images/sastec motard.jpg" />
                    <img src="Resources/Images/YKK70x50.jpg" />
                    <img src="Resources/Images/turtle_.jpg" />
                    <img src="Resources/Images/humax.jpg" />
                    <img src="Resources/Images/cordura.jpg" />
                </div>
            </div>

            <!--import video content-->
            <div class="md-layout mt-30">
                <div class="md-layout-item md-size-100 mb-20 txtHelite">
                    <h3 v-html="$t('tab.video')"></h3>
                </div>
                <div class="md-layout-item md-size-100 center" > 
                    <iframe frameborder="0" src="https://www.youtube.com/embed/FZYfikej8XA" style="width:620px; height:350px;"></iframe>
                </div>
            </div>
        </div>
       
    </md-content>
</template>
<i18n src="../../assets/I18N/Screen.Global.json"></i18n>
<i18n src="../../assets/I18N/products/Turtle_hi_vis2.json"></i18n>

<style scoped>
    .price { font-size: 20px; font-weight: 600 !important; }
    .md-price { color:#2fa0c2;}
    .md-discount { color: rgb(212, 44, 44);}

    .md-list-item-default {
        margin-top: -20px;
    }
    .tableModif {
        background-color: #017CBD;
        color:#FFFFFF;
        font-size: 11px;
    }
    tbody {
        text-align: left;
        color: #333333;
    }
    td {
        border-bottom: 1px solid #CCC;
        
    }
    .tableHeadSpace {
        padding: 10px;
        height: 56px;
    }
    .button {width:150px ;}
    a.button:hover {background-color:#999999;}
</style>
<script>
    import InnerImageZoom from 'vue-inner-image-zoom';
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

export default {
    name: 'Turtle_hi_vis2', 
    mixins: [apiECommerceExtension],
    data: () => ({
        isOnlineStore: $App.onlineStore,
        Product: null,
    }),
    mounted: async function () {
        this.Product = await this.EcommerceProductGetById(1760);
    },
    methods:{
        changeImage: function(imageSrc,imageZoomed){
            this.$refs.principalIMG.src = imageSrc;
            this.$refs.principalIMG.zoomSrc = imageZoomed;
        },
        navigateTo: async function () { 
            await this.AddItem(this.Product);
            this.$router.push('/order');
        },
    },

        components: {
            'inner-image-zoom': InnerImageZoom
        }
}

</script>