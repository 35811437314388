<template>
  <div v-if="invoice">
    <div class="" style="min-height:550px;"> 
      <div class="md-layout mt-30">

        <div class="md-layout-item md-layout md-size-75 md-medium-size-100">

          <!--Top Title-->
          <div class="md-layout-item md-size-100 default-pad">
            <h1>{{ $t("invoice.title") }}</h1>
              <div v-if="invoice.balance != 0 && !invoice.paidOn" class="md-layout md-mt-3 md-pl-3">
                <h2>{{ $t("invoice.slogan") }}</h2>
              </div>
              <div v-else class="md-layout md-mt-3 md-pl-3">
                <h2>
                  <span v-html="$t('invoice.paidText')"></span>
                  <span v-if="invoice">{{ invoice.uId }}</span>
                  <span v-html="$t('invoice.paidText2')"></span>
                </h2>
              </div>
          </div>

          <div class="md-layout-item md-size-100 default-pad" v-if="invoice.balance != 0 && !invoice.paidOn"> 

            <!--Info Section-->
            <div class="md-layout-item md-size-100 md-mt-5">
              <div class="md-layout-item md-size-100">
                <h1 class="">{{ $t("invoice.info") }}</h1>
              </div>
              <div class="md-layout-item md-layout md-size-100 md-mt-5">

                <div class="md-layout-item md-layout md-small-size-100 md-px-3">

                  <div class="md-layout-item md-size-100 md-layout">
                    <div class="md-layout-item md-layout md-alignment-top-left">
                        <span class="md-subheading" >{{ $t("general.name") }}:</span>
                      </div>
                    <div class="md-layout-item md-layout md-alignment-top-right">
                      <label v-if="invoice.accountName" >{{invoice.accountName}}</label>
                    </div>
                  </div>

                  <div class="md-layout-item md-size-100 md-layout">
                    <div class="md-layout-item md-layout md-alignment-top-left">
                        <span class="md-subheading" >{{ $t("general.ref") }}:</span>
                      </div>
                    <div class="md-layout-item md-layout md-alignment-top-right">
                      <label v-if="invoice.uId" >{{invoice.uId}}</label>
                    </div>
                  </div>
  
                </div>
                <div class="md-layout-item md-layout md-small-size-100 md-px-5">&nbsp;</div>
  
              </div>
            </div>
            
            <!--Items Section-->
            <div class="md-layout-item md-size-100 md-mt-5">
              <div class="md-layout-item md-size-100">
                <h1>{{ $t("general.items") }}</h1>
              </div>

              <div v-if="invoice && invoice.items && invoice.items.length > 0" class="md-layout-item md-layout md-size-100 md-mt-3 ">
                <div class="md-layout-item md-layout md-size-100 md-xsmall-hide">
                  <div class="md-layout-item md-layout md-size-60 md-small-size-55 md-xsmall-size-100"><span class="md-title  md-pl-3">{{ $t("general.description") }}</span></div>
                  <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-title">{{ $t("general.price") }}</span></div>
                  <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-title">{{ $t("general.discount") }}</span></div>
                  <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-title">{{ $t("general.qty") }}</span></div>
                  <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-title">{{ $t("general.total") }}</span></div>
                </div>
                <div class="md-layout-item md-layout md-size-100">
                  <div v-for="(item, i) in invoice.items" :key="i" class="md-layout-item md-layout md-size-100 md-my-3">
                    <div class="ItemsBox md-layout md-size-100">
                      <div class="md-layout-item md-layout md-size-60 md-small-size-55 md-xsmall-size-100 md-alignment-center-left md-pl-2"><span class="md-element" v-html="$options.filters.textWithBR(item.description)" ></span></div>
                      <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-element">{{item.price}}$</span></div>
                      <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center md-discount"><span class="md-discount">{{item.discount}}$</span></div>
                      <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-element">{{item.qty}}</span></div>
                      <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><span class="md-total">{{item.total}}$</span></div>
                    </div>
                  </div>
                  <div class="md-layout-item md-layout md-size-100">{{ $t("shipping.deliveryDelay") }}</div>
                </div>

              </div>
            </div>
           
          </div>


        </div>

        <!--Summary Step Desktop Mobile-->
        <div v-if="invoice && invoice.balance != 0 && !invoice.paidOn" class="md-layout-item md-layout">
          <div class="md-layout-item md-layout invoiceBG"> <!--invoiceBG is the class that change it to mobile-->
            <div class="md-layout-item default-pad-invoice">
              <div class="md-layout md-layout-item md-size-100">
                <h2 class="f-secondary">{{ $t("invoice.summary") }}</h2>
              </div>
              <div  class="md-layout md-layout-item md-size-100 " style="margin-top: 30px;"> <!--v-if="invoice.balance"-->

                <div class="md-layout md-layout-item md-size-100">
                  <h2 class="titleStyle">{{ $t("invoice.choice") }}</h2>
                  <div class="md-layout md-layout-item md-size-100" v-if="this.invoice">
                    <md-radio v-model="invoice.paymentTypeId" value="0" class="f-bold" style="font-size: larger;">Paypal</md-radio>
                  </div>
                </div>

                <div class="md-layout md-layout-item md-size-100 pt-15">
                  <div class="md-layout-item md-size-66" >
                    <h4 class="priceTitle eCommerce-h4">{{ $t("invoice.subtotal") }}</h4>
                  </div>
                  <div class="md-layout-item" >
                    <h4 class="price eCommerce-h4">{{(invoice.subTotal)}}$</h4><!--{{$filters.$(invoice.subTotal)}}$-->
                  </div>
                </div>

                <div class="md-layout md-layout-item md-size-100">
                  <div class="md-layout-item md-size-66" >
                    <h4 class="priceTitle eCommerce-h4">{{ $t("invoice.shipping") }}</h4>
                  </div>
                  <div class="md-layout-item ">
                    <h4 class="price eCommerce-h4">{{(invoice.shipping)}}$</h4><!--{{$filters.$(invoice.shipping)}}$-->
                  </div>
                </div>

                <div class="md-layout md-layout-item md-size-100">
                  <div class="md-layout-item md-size-66" >
                    <h4 class="priceTitle eCommerce-h4">{{ $t("invoice.GST") }}</h4>
                  </div>
                  <div class="md-layout-item ">
                    <h4 class="price eCommerce-h4">{{(invoice.gst)}}$</h4><!--{{$filters.$(invoice.gst)}}$-->
                  </div>
                </div>

                <div class="md-layout md-layout-item md-size-100 pb-15">
                  <div class="md-layout-item md-size-66" >
                    <h4 class="priceTitle eCommerce-h4">{{ $t("invoice.PST") }}</h4>
                  </div>
                  <div  class="md-layout-item ">
                    <h4 class="price eCommerce-h4">{{(invoice.pst)}}$</h4><!--{{$filters.$(invoice.pst)}}$-->
                  </div>
                </div>

                <hr class="hr">

                <div class="md-layout md-layout-item md-size-100 pt-15">
                  <div class="md-layout-item md-size-65" >
                    <h4 class="priceTitle eCommerce-h4">{{ $t("invoice.total") }}</h4>
                  </div>
                  <div class="md-layout-item ">
                    <h4 class="price eCommerce-h4">{{(invoice.balance)}}$</h4> <!--{{this.balance}}-->
                  </div>
                </div>
          
                <div class="md-layout md-layout-item md-size-100 pt-10">
                  
                  <md-button class="mt-5 orderBtn" :readonly="loading == true">
                    <div>
                      <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">{{ $t("invoice.treatment") }}</h2>
                      <h2 v-if="loading == false" class="orderBtnTitle" @click="payInvoice()">{{ $t("invoice.paid") }}</h2>
                    </div>               
                  </md-button>
                </div>
                <div class="md-layout md-layout-item md-size-100 md-alignment-center-center">
                  <div class="md-layout md-layout-item md-size-100 md-alignment-center-center pt-5" v-if="loading == true">
                    <md-progress-bar md-mode="indeterminate" style="height:10px; width:100%;"></md-progress-bar>
                    <p class="" style="font-size: small; cursor: pointer; text-align: center; color: rgb(var(--primary));" @click="cancelInvoice()">{{ $t("invoice.resetPayment") }}</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="backgroundCanceled"></div>
        </div>

      </div>

    </div>
  </div>
</template>
<i18n src="../../assets/I18N/eCommerce/invoice.json"></i18n>
<i18n>
  {
    "en": {
      "shipping":{
        "deliveryDelay": "Legende: ** = delivery delay"
      }
    },
    "fr": {
      "shipping":{
        "deliveryDelay": "Légende: ** = délais de Livraison"
      }
    }
  }
</i18n>

<style scoped>
    label {
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
    }
    .md-subheading { font-weight: bold;}

  .md-element { color: rgba(var(--primary)); font-size:20px; text-align: left; }
  .md-discount { color: rgba(var(--secondary),0.3); font-size:20px; }
  .md-total { color: rgba(var(--secondary),0.7); font-size:20px; }

  h2 { text-align: left;}
  /* h3 {text-transform: unset; color: rgba(000,000,000,0.5); font-size:27px; } */

  .titleStyle {color: rgba(var(--primary)); font-weight: 700 !important;}
  .itemPrice {font-size:10px;}
  .grid-pad {padding: 0px 20px;}
  .ItemTitle {font-weight: 700;}
  .ItemTexte {text-align: right;}
  .subtitleSum { line-height: 35px; font-size: 36px; font-family: Ephesis; text-align: left; color: rgba(000,000,000,0.5);}
  .eCommerce-h4 {font-size:large;}
  .hr {width:75%; color: rgba(var(--primary)); display: block; margin-left: auto !important; margin-right: auto !important;}
  .display-in {display: inline;}
  .sectionTitle { font-size: 48px; font-family: Ephesis; text-align: left; color: rgba(var(--secondary),0.5);}
  
  /*Btn*/
  .orderBtn{display: block; margin-left: auto !important; margin-right: auto !important; width: auto; padding: 0px 20px !important; background-color: white; border: 1px solid white; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);}
  .orderBtn:hover {background-color: rgba(var(--secondary),0.9); border: 1px solid rgb(var(--secondary)); box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);}
  .orderBtnTitle {color: rgb(var(--secondary));}
  .orderBtnTitle:hover {color: rgb(var(--tierce));}

  .invoiceBG { display: block; position:fixed; height: auto; background-color: #FAFAFA !important; z-index: 100;border-left: 1px solid rgb(var(--secondary));}
  .default-pad-invoice {padding: 0px 30px 0px 15px;}
  .priceTitle {text-transform: uppercase;  color: rgb(var(--primary)); text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); height: 40px; padding-top: 5px !important;}
  .price {text-align: right; color: rgb(var(--secondary)); text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); font-weight: 700;}
  .ItemsBox {position:relative; height: 140px; color: rgba(var(--secondary)); font-weight: 700; background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }
  .ItemsDescription {color: rgba(var(--secondary)); font-size: small; margin: -30px 0px 0px 10px !important; display: inline-block;}
  .padItemBox {padding: 0px 200px 0px 200px; margin: 0px 0px 0px 0px !important;} 
  .clicker {cursor:pointer;}
  .backgroundCanceled {
        display: block;
        position: absolute;
        height: 100%;
        width:450px;
        background-image: url("../../../public/Resources/Images/invoice/background1.png"); 
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: 100%; 
        right: 0%;
        bottom: 15px;
        z-index: -1;
        opacity:0.5;
        overflow: hidden;
    }
  @media handheld, only screen and (max-width: 600px) {
    .ItemsBox {margin: 0px !important;}
  }
  @media handheld, only screen and (max-width: 960px) {
    .soustitre{font-size: 36px; padding-left: 5px !important;}
    .default-pad-invoice {padding: 0px;}
    .default-pad {padding: 0px;}
    .invoiceBG { position:relative; margin: 50px 0px 0px 0px !important; border-left:none; padding: 0px 10px 0px 10px;}
    .ItemsBox { height: 105px;}
  }
  @media handheld, only screen and (max-width: 1264px) {
    .padItemBox {padding: 0px;} 
    
    .invoiceBG { position:relative; margin: 50px 0px 0px 0px !important;}
  }
    
</style>

<script>
//import Summary from '../../components/infoPayment.vue'
import { apiECommerceExtension } from '../../mixins/apiECommerceExtension'
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';

export default {
  name: 'Invoice', 
  mixins: [apiECommerceExtension, navigateRouteExtension],
  props: {
    //summary: Object,
  },
  data: () => ({
    invoice: null,
    invoiceToPaid: true,
    loading: false,
    }),
  mounted: async function () {

    if(!$App.onlineStore) { this.$router.replace('/'); }
    else {

      let param = this.$route.params.id;
      if (!param) {
        param = this.GetInvoiceRef();
        if (param) { param = param.replace(/"/g, ''); } else { this.$router.push('/Home'); }
      }
      await this.readData(param);

      if(this.invoice) { 
        if (this.invoice.balance == 0 && this.invoice.paidOn) {
          await this.ClearInvoice();
          let paramReload = this.GetInvoiceRef();
          paramReload = this.$route.params.id;
          if (!paramReload) { this.$router.push('/Home'); }
        }
      }
      else{ this.$router.push('/Home'); }

    }

  },
  methods:{
    readData: async function(param){

      let data = await this.GetInvoice(param);
      if(data) {
        if(data.paymentTypeId != 0){
          this.loading = true;
        }
      }
      this.invoice = data;

    },
    payInvoice: async function(){
      this.loading = true;
      let urlToPay = '';

      if (this.invoice.uId) { urlToPay = await this.PaypalInit(this.invoice.uId); }
      if (urlToPay != "") {
        window.location.href = urlToPay;
      }

    },
    cancelInvoice: async function(){
      if(this.invoice && this.invoice.uId ) { 
        if(this.invoice.paymentTypeId == 7){
          this.$router.push('/invoices/'+ this.invoice.uId + '/paypal/canceled');
        } else { window.location.reload(); }
      }

    }
  },
  
  components: {
    // Summary: Summary
  },
}

</script>