<template>
    <!--Legal mention-->
    <div class="md-content">
        <h1 class="md-mt-2">{{ $t("general.legal") }}</h1>
        <div class="md-layout-item md-layout md-size-100 t-l md-p-4">
            <span v-html="$options.filters.textWithBR($t('general.legalText'))"></span>
        </div>
    </div>
</template>
<style scoped>

</style>

<i18n src="../assets/I18N/Screen.Global.json"></i18n>

<script>
    export default {
        name: 'legal-notice',
        mixins: [],
        data: () => ({
        }),
        computed:{
        },
        mounted: async function () {

        },
        methods: {
        },        
        component: {
        }
    }
</script>