<template>
    <div class="footer default-pad md-layout mt-55">

            <!--Helite address-->
            <div class=" md-layout-item md-alignment-center t-l md-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100 pt-60 pb-20">
                <p class="footertitle" v-html="$t('title')"></p>
                <div class="md-layout-item footertxt mt-20">
                    <!-- <p v-html="$t('street')"></p>
                    <p v-html="$t('town')"></p> -->
                    <p v-html="$t('email')"></p>
                    <p v-html="$t('phone')"></p>
                    <p v-html="$t('fax')"></p>
                    <div class="footerCookieLink"><a @click="openCookie()" style="color: #848484;" v-html="$t('cookie')"></a></div>
                    <div class="footerCookieLink"><a @click="navigateToPolicy()" style="color: #848484;" v-html="$t('policy')"></a></div>
                    <br/> <br/>
                    <p v-html="$t('copyWrite')"></p>
                </div>
            </div>

            <!--Shortcut list-->
            <div class="md-layout-item md-alignment-center md-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100 md-layout pt-10 pl-40">
                <md-list class="md-layout-item md-layout">
                    <div class="md-layout-item">
                        <br/>
                        <router-link to="/home" v-html="$t('home')"></router-link><br/>
                        <router-link to="/products" v-html="$t('products')"></router-link><br/>
                        <router-link to="/faq" v-html="$t('FAQ')"></router-link><br/>
                        <router-link to="/airbag/mechanical" v-html="$t('mechanical')"></router-link><br/>
                        <router-link to="/airbag/electronic" v-html="$t('electronique')"></router-link><br/>
                        <router-link to="/contact" v-html="$t('contact')"></router-link><br/>                       
                    </div>
                </md-list>
            </div>

            <!--Facebook link-->
            <div class="md-layout-item md-alignment-center md-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100 fbtxt pt-70 pb-20">
                <a href="https://www.facebook.com/heliteairbagsysteme/" target="_blank">
                    <img src="/Resources/Images/facebook-logo.png" alt="" />
                    <br/><br/>
                    <p v-html="$t('facebook')"></p>
                </a>
            </div>

            <!--French logo-->
            <div class="md-layout-item md-alignment-center md-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100 francetxt pt-70 pb-40">
                <img src="/Resources/Images/france.png" alt="" />      
                <br/>
                <p v-html="$t('fabrication')"></p>
            </div>

    </div>          
</template>
<i18n>
    {
        "fr":{
            "title":"HELITE CANADA",
            "street":"230 rue Paradis",
            "town":"J0C 1K0 St-Germain",
            "email":"Email : <a class='emailtxtFooter' href='mailto:helite-canada@helite.ca'>helite-canada@helite.ca</a>",
            "phone":"Tel. : 1 (873) 382-2070",
            "fax":"Fax : 1 (819) 395-4701",
            "cookie":"Mes témoins",
            "policy":"Politique de confidentialité",
            "copyWrite":"Helite Canada tous droits réservés &copy;2024.03.22.0",
            "home":"Accueil",
            "products":"Produits",
            "FAQ":"F.A.Q",
            "mechanical":"Aigbag - Mécanique",
            "electronique":"Airbag - Électronique",
            "airbag":"Système airbag",
            "protection":"Protection",
            "turtle":"Technologie Turtle",
            "contact":"Contact",
            "facebook":"Rejoignez-nous ! <br/> sur Facebook",
            "fabrication":"Fabrication <br/> Française"
        },
        "en":{
            "title":"HELITE CANADA",
            "street":"230 rue Paradis",
            "town":"J0C 1K0 St-Germain",
            "email":"Email : <a class='emailtxtFooter' href='mailto:helite-canada@helite.ca'>helite-canada@helite.ca</a>",
            "phone":"Phone : 1 (873) 382-2070",
            "fax":"Fax : 1 (819) 395-4701",
            "cookie":"My cookies",
            "policy":"Privacy Policy",
            "copyWrite":"Helite Canada tous droits réservés &copy;2024.03.22.0",
            "home":"Home",
            "products":"Products",
            "FAQ":"F.A.Q",
            "mechanical":"Airbag - Mechanical",
            "electronique":"Airbag - Electronic",
            "airbag":"Airbag system",
            "protection":"Protection",
            "turtle":"Turtle Technology",
            "contact":"Contact",
            "facebook":"Join us ! <br/> on Facebook",
            "fabrication":"French <br/> Fabrication"
        }
    }
</i18n>


<style scoped>
    .footer { background-color: #222222; position:relative;}
    .md-list {background-color: transparent !important; text-align: start !important;}
    .md-list .md-layout-item a {color: #848484; line-height:15px; font-size:18px;}
    .md-list .md-layout-item a:hover {color:#2fa0c2;}
    .footertitle {color:#2fa0c2;font-size:20px;font-weight:bold;}
    .footertxt {font-size:14px;color:#848484; line-height:15px;}
    .fbtxt {font-weight:bold;color:#3c599b !important;}
    .fbtxt :hover {text-decoration:none !important;}
    .francetxt {font-weight:bold;/*color:;*/color:#848484;}
    .footerCookieLink { display: inline-block; padding-right: 10px; color:#848484;}
    .footerCookieLink:hover { cursor: pointer;}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 958px) {
      .default-pad { padding: 0px; }
    }
    @media handheld, only screen and (max-width: 1264px) {}
    
</style>
<script>
    export default {
        name: 'content-footer',
        data: () => ({
        }),
        mounted: async function () {
        },
        methods: {
            navigateToPolicy: function(){
                this.$router.push('/PolitiqueDeConfidentialiter');
            },
            openCookie: function(){
                $App.event.$emit('openCookie');
            }
        },
    }
</script>