import { render, staticRenderFns } from "./canceled.vue?vue&type=template&id=3feda3d1&scoped=true&"
import script from "./canceled.vue?vue&type=script&lang=js&"
export * from "./canceled.vue?vue&type=script&lang=js&"
import style0 from "./canceled.vue?vue&type=style&index=0&id=3feda3d1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3feda3d1",
  null
  
)

/* custom blocks */
import block0 from "../../../assets/I18N/eCommerce/invoice.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CeCommerce%5CPaypal%5Ccanceled.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports