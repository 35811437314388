<template>
    <md-content>
            <div class="md-layout txtHelite mt-20 default-pad">

                <!--top left content-->
                <div class="md-layout-item md-size-35 md-small-size-100">
                    <div class="md-layout-item md-size-100">
                        <h3>Helite Canada</h3>
                    </div>
                    <div class="md-layout-item md-size-100 mt-60"> 
                        <p>Pier-Philippe Gemme</p>
                        <!-- <p>230 rue Paradis</p>
                        <p>J0C 1K0 St-Germain</p>
                        <br/>
                         -->
                        <p>Email : <a class="mailContact" href="mailto:helite-canada@helite.ca">helite-canada@helite.ca</a></p>
                        <br/>
                        <p v-html="$t('contact.phone')"></p>
                        <p>Fax. : 1 (819) 395-4701</p>
                    </div>
					
                </div>

                <!--top right content-->
                <div class="md-layout-item md-size-65 md-small-size-100">
                    <div class="md-layout-item md-size-100">
                        <h3 v-html="$t('contact.title')"></h3>
                    </div>
                    <div class="md-layout-item md-size-100 mt-40"> 
                        <a href="https://www.kimpex.com/fr-ca/trouver-detaillant" target="_blank"><img src="/Resources/Images/kimpex_logo.png" title="" alt="" /></a>
                    </div>
                    <div class="md-layout-item md-size-100 mt-40">
                        <h3 v-html="$t('contact.where')"></h3>
                    </div>
                    <div class="md-layout-item md-size-100 mb-50 mt-20"> 
                        <md-table>
                            <md-table-row>
                              <md-table-head class="txtHeadTable" ><p v-html="$t('contact.retailer')"></p></md-table-head>
                              <md-table-head class="txtHeadTable" ><p v-html="$t('contact.localisation')"></p></md-table-head>
                              <md-table-head class="txtHeadTable" ><p v-html="$t('contact.phoneTitle')"></p></md-table-head>
                              <md-table-head class="txtHeadTable" ><p v-html="$t('contact.map')"></p></md-table-head>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>ACC. DE MOTO ADM / QUEBEC</md-table-cell>
                                <md-table-cell>QUEBEC / QC</md-table-cell>
                                <md-table-cell>418-527-4489</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1831+Boulevard+Wilfrid-Hamel,+Qu%C3%A9bec,+QC+G1N+3Z1/@46.8052358,-71.2848543,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb89701113a5503:0x68f395d3eb139d99!8m2!3d46.8052358!4d-71.2826656" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>ACC. DE MOTO ADM/ST-HYACINTHE</md-table-cell>
                                <md-table-cell>ST-HYACINTHE / QC</md-table-cell>
                                <md-table-cell>450-252-4488</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/3003+Rue+Picard,+Saint-Hyacinthe,+QC+J2S+1H4/@45.6377391,-72.97672,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc84e18f8472697:0xc6b2861f9b27b0c8!8m2!3d45.6377391!4d-72.9745313" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>ADVENTURE POWER PRODUCTS</md-table-cell>
                                <md-table-cell>ILE DES CHÊNES / MB</md-table-cell>
                                <md-table-cell>204-878-3194</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/797+Quest+Blvd,+Ile+des+Ch%C3%AAnes,+MB+R0A+0T0/@49.7147356,-96.998776,17z/data=!3m1!4b1!4m5!3m4!1s0x52c1d0a622b82651:0x22385771a8047cd7!8m2!3d49.7147356!4d-96.9965873" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>BLACKFOOT MOTORCYCLE LTD</md-table-cell>
                                <md-table-cell>CALCAGY / AB</md-table-cell>
                                <md-table-cell>403-243-2636</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/6+Highfield+Cir+SE,+Calgary,+AB+T2G+5N5/@51.0120981,-114.048743,17z/data=!3m1!4b1!4m5!3m4!1s0x53717065b5865725:0x5a5a1cc578521394!8m2!3d51.0120981!4d-114.0465543" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>BMW GABRIEL MOTO</md-table-cell>
                                <md-table-cell>MONTRÉAL / QC</md-table-cell>
                                <md-table-cell>514-712-7777</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/6695+Rue+Saint-Jacques,+Montr%C3%A9al,+QC+H4B+1V3/@45.4599915,-73.62921,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc910cc5e774eb1:0xdf5ec3f28ecb3138!8m2!3d45.4599878!4d-73.6270213" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>BOUTIQUE LINUS INC/DBA FORTNINE</md-table-cell>
                                <md-table-cell>QUEBEC / QC</md-table-cell>
                                <md-table-cell>418-682-0800</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1841+Boulevard+Wilfrid-Hamel,+Qu%C3%A9bec,+QC+G1N+3Y9/@46.8051277,-71.2847497,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb897013e709d79:0x83804996533bca7d!8m2!3d46.8051277!4d-71.282561" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>BURNABY KAWASAKI</md-table-cell>
                                <md-table-cell>BURNABY / BC</md-table-cell>
                                <md-table-cell>604-525-9393</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=BURNABY+KAWASAKI&ll=49.2249990,-122.9396540" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>CYCLE WORKS CALGARY LTD.</md-table-cell>
                                <md-table-cell>CALGARY / AB</md-table-cell>
                                <md-table-cell>403-230-1920</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=CYCLE+WORKS+CALGARY+LTD&ll=51.0893269,-114.0490681" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>ENTREPRISE J.E.GOULET</md-table-cell>
                                <md-table-cell>SAINTE-LUCE / QC</md-table-cell>
                                <md-table-cell>418-739-4219</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=ENTREPRISE+JEGOULET&ll=48.5309618,-68.3495583" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>EXCALIBUR MOTORCYCLE WORKS LTD</md-table-cell>
                                <md-table-cell>THUNDER BAY / ON</md-table-cell>
                                <md-table-cell>807-622-0007</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1425+Walsh+St+W,+Thunder+Bay,+ON+P7E+4X6/@48.3736264,-89.3043181,17z/data=!3m1!4b1!4m5!3m4!1s0x4d591fc09f945fa3:0x9b64fe215ea14c9!8m2!3d48.3736264!4d-89.3021294" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>GP BIKES INC.</md-table-cell>
                                <md-table-cell>WHITBY / ON</md-table-cell>
                                <md-table-cell>905-428-8983</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1100+Champlain+Ct,+Whitby,+ON+L1N+6K9/@43.8675586,-78.9197269,17z/data=!3m1!4b1!4m5!3m4!1s0x89d51df05a3754fb:0x976bea6cd9a321a2!8m2!3d43.8675586!4d-78.9175382" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>GROUPE CONTANT INC</md-table-cell>
                                <md-table-cell>MIRABEL / QC</md-table-cell>
                                <md-table-cell>450-434-6676</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=GROUPE+CONTANT&ll=45.6865159,-73.9268303" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>GROUPE CONTANT INC</md-table-cell>
                                <md-table-cell>VAUDREUIL DORION / QC</md-table-cell>
                                <md-table-cell>514-360-6676</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=GROUPE+CONTANT&ll=45.4089749,-74.0415458" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>HULLY GULLY (LONDON) LTD.</md-table-cell>
                                <md-table-cell>LONDON / ON</md-table-cell>
                                <md-table-cell>1 888-706-5436</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1705+Wharncliffe+Rd+S,+London,+ON+N6L+1A7/@42.9245521,-81.2795862,17z/data=!3m1!4b1!4m5!3m4!1s0x882ef1342a041401:0x98a070ed7f627aa!8m2!3d42.9245521!4d-81.2773975" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>JA-PER-FORMANCE INC.</md-table-cell>
                                <md-table-cell>ARTHABASKA / QC</md-table-cell>
                                <md-table-cell>819-357-9677</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=JA-PER-FORMANCE&ll=46.0295254,-71.9313578" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>LEATHER KING</md-table-cell>
                                <md-table-cell>WINDSOR / ON</md-table-cell>
                                <md-table-cell>519-971-1715</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=LEATHER+KING&ll=42.2812832,-83.0093386" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>LEROUX AUTO PARTS</md-table-cell>
                                <md-table-cell>CHELMSFORD / ON</md-table-cell>
                                <md-table-cell>705-855-9075</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/3576+Errington+Ave+N,+Chelmsford,+ON+P0M+1L0/@46.5828465,-81.2001532,17z/data=!3m1!4b1!4m5!3m4!1s0x4d2fb140bb666c65:0x676515070f5d5d4a!8m2!3d46.5828465!4d-81.1979645" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>LES ENT. DENIS BOISVERT INC.</md-table-cell>
                                <md-table-cell>SHERBROOKE / QC</md-table-cell>
                                <md-table-cell>819-565-1376</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/294+Rue+Queen,+Sherbrooke,+QC+J1M+1K6/@45.3767697,-71.8657128,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb7b2b803619a31:0x2755df150c415bb9!8m2!3d45.3767697!4d-71.8635241" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>MODERN MOTORCYCLING LTD.</md-table-cell>
                                <md-table-cell>VANCOUVER / BC</md-table-cell>
                                <md-table-cell>604-876-0182</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/2816+Commercial+Dr,+Vancouver,+BC+V5N+4C6/@49.2592429,-123.0716677,17z/data=!3m1!4b1!4m5!3m4!1s0x5486714dcd5a1163:0x53d20dfc262db302!8m2!3d49.2592429!4d-123.069479" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>MOTOS ILLIMITEES INC.</md-table-cell>
                                <md-table-cell>TERREBONNE / QC</md-table-cell>
                                <md-table-cell>450-477-4000</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=MOTOS+ILLIMITEES&ll=45.7247781,-73.6911941" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>MOTOS THIBAULT MAURICIE (1992) INC.</md-table-cell>
                                <md-table-cell>TROIS-RIVIERES / QC</md-table-cell>
                                <md-table-cell>819-375-2222</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/205+Rue+Dessureault,+Trois-Rivi%C3%A8res,+QC+G8T+2L7/@46.3779371,-72.5310152,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc7c87f1ce16793:0x9197b64f82d5d5e5!8m2!3d46.3779371!4d-72.5288265" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>MOTOS THIBAULT SHERBROOKE INC</md-table-cell>
                                <md-table-cell>SHERBROOKE / QC</md-table-cell>
                                <md-table-cell>819-569-1173</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=MOTOS+THIBAULT+SHERBROOKE&ll=45.3918160,-71.9597330" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>NADON SPORT ST-EUSTACHE INC.</md-table-cell>
                                <md-table-cell>ST-EUSTACHE / QC</md-table-cell>
                                <md-table-cell>450-473-2381</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/645+Rue+Dubois,+Saint-Eustache,+QC+J7P+3W1/@45.5865651,-73.8771455,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc925f651298545:0xdd2cd06c4f40237b!8m2!3d45.5865651!4d-73.8749568" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>POWER SPORTS CANADA INC.</md-table-cell>
                                <md-table-cell>NEPEAN / ON</md-table-cell>
                                <md-table-cell>613-224-7899</md-table-cell>
                                <md-table-cell><a href="https://www.google.ca/maps/place/1+Laser+St,+Nepean,+ON+K2E+7V1/@45.3358659,-75.7059618,17z/data=!3m1!4b1!4m5!3m4!1s0x4cce07c53fde9a9d:0x4bcc5d9b088d96a9!8m2!3d45.3358659!4d-75.7037731" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>PROSTYLE POWERSPORTS</md-table-cell>
                                <md-table-cell>PORT COLBORNE / ON</md-table-cell>
                                <md-table-cell>905-835-5000</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=PROSTYLE+POWERSPORTS&ll=42.8904694,-79.2519648" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>RIDER S CHOICE INC.</md-table-cell>
                                <md-table-cell>MISSISSAUGA / ON</md-table-cell>
                                <md-table-cell>905-896-7433</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=RIDER+S+CHOICE&ll=43.6029325,-79.5754451" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>SPORT V.L. / CHICOUTIMI</md-table-cell>
                                <md-table-cell>CHICOUTIMI / QC</md-table-cell>
                                <md-table-cell>418-698-6980</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=SPORT+VL&ll=48.3985394,-71.1028446" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>SPORT V.L. / JONQUIERE</md-table-cell>
                                <md-table-cell>JONQUIERE / QC</md-table-cell>
                                <md-table-cell>418-695-6950</md-table-cell>
                                <md-table-cell><a href="https://maps.google.com/maps?q=SPORT+VL&ll=48.4124429,-71.2308826" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>SPIN CYCLE PRODUCTS</md-table-cell>
                                <md-table-cell>PICTOU / NS</md-table-cell>
                                <md-table-cell>519-770-9988</md-table-cell>
                                <md-table-cell><a href="https://www.google.com/maps/place/Spin+Cycle+Products%2F+Turncycle/@45.7189126,-62.65461,17z/data=!4m14!1m7!3m6!1s0x4b5e9f1dc69a16cd:0x47af637b8682a302!2sSpin+Cycle+Products%2F+Turncycle!8m2!3d45.7189089!4d-62.6520297!16s%2Fg%2F11frpgjgb8!3m5!1s0x4b5e9f1dc69a16cd:0x47af637b8682a302!8m2!3d45.7189089!4d-62.6520297!16s%2Fg%2F11frpgjgb8?entry=ttu" target="_blank"><img src="Resources/Images/pin.png" alt="" /></a></md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </div>
    </md-content>
</template>
<i18n>
    {
        "fr": {
            "contact": {
                "phone":"Tel. : 1 (873) 382-2070",
                "title":"Trouver un détaillant",
                "where":"Où essayer nos produits?",
                "retailer":"Commerçant",
                "localisation":"Localisation",
                "phoneTitle":"Téléphone",
                "map":"Google map"
            }
        },
        "en": {
            "contact":{
                "phone":"phone : 1 (873) 382-2070",
                "title":"Find a Retailer",
                "where":"Where to try our products?",
                "retailer":"Retailer",
                "localisation":"Location",
                "phoneTitle":"Phone",
                "map":"Google Map"
            }
         }
    }    
</i18n>

<style scoped>
    .md-table-row td {
        border-bottom: 1px solid #ccc;
    }
    .txtHeadTable {
        font-size: 11px;
    }
</style>
<script>
    export default {

        name: 'Contact',
        data: () => ({
        }),
        mounted: async function () {
            //this.$i18n.mergeLocaleMessage('fr', i18nContact.fr);
            //this.$i18n.mergeLocaleMessage('en', i18nContact.en);
        }
    }

</script>