import { render, staticRenderFns } from "./Electronic.vue?vue&type=template&id=698a6f8a&scoped=true&"
import script from "./Electronic.vue?vue&type=script&lang=js&"
export * from "./Electronic.vue?vue&type=script&lang=js&"
import style0 from "./Electronic.vue?vue&type=style&index=0&id=698a6f8a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698a6f8a",
  null
  
)

/* custom blocks */
import block0 from "../../assets/I18N/airBag/electronic.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5Cairbag%5CElectronic.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports