<template>
  <div>
        <!-- section policy-->
        <section id="contenu">
        <div class="grid-pad">
          <div>
            <h1 class="f-primary" style="margin-top: 50px;" v-html="$t('policy.pageTitle')"></h1>
          </div>
          <div class="margeSection">
            <p v-html="$t('policy.p1')"></p>
            <p v-html="$t('policy.p2')"></p>
            <p v-html="$t('policy.p3')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-l" v-html="$t('policy.s2Title')"></h2>
            <p  v-html="$t('policy.s2P1')"></p>
            <p  v-html="$t('policy.s2P2')"></p>
          </div>
          <div class="txt-c">
            <h2 class="txt-c" v-html="$t('policy.s3Title')"></h2>
            <p v-html="$t('policy.s3P1')"></p>
            <ul class="grid-pad txt-l">
              <li v-html="$t('policy.s3Li1')"></li>
              <li v-html="$t('policy.s3Li2')"></li>
              <li v-html="$t('policy.s3Li3')"></li>
              <li v-html="$t('policy.s3Li4')"></li>
              <li v-html="$t('policy.s3Li5')"></li>
              <li v-html="$t('policy.s3Li6')"></li>
            </ul>
            <p v-html="$t('policy.s3P2')"></p>
          </div>
          <div class="col-1-1 margeSection">
            <div class="col-1-2 ">
              <h2 class="txt-c " style="line-height: normal; padding: 2%;" v-html="$t('policy.s4Title')"></h2>
            </div>
            <div class="col-1-2">
              <p class=" f-primary fw-700" style="text-align: center;" v-html="$t('policy.s4P1')"></p>
              <p class="mt-8" style="text-align: center;" v-html="$t('policy.s4P2')"></p>
              <p class="" style="text-align: center;" v-html="$t('policy.s4P3')"></p>
              <p class="f-nobold" style="text-align: center;" v-html="$t('policy.s4P4')"></p>
            </div>
          </div>
          <div class="margeSection">
            <p  v-html="$t('policy.s4P5')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-c" v-html="$t('policy.s5Title')"></h2>
            <p v-html="$t('policy.s5P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-r f-primary" v-html="$t('policy.s6Title')"></h2>
            <p v-html="$t('policy.s6P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-l" v-html="$t('policy.s7Title')"></h2>
            <p v-html="$t('policy.s7P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-c f-secondary" v-html="$t('policy.s8Title')"></h2>
            <p v-html="$t('policy.s8P1')"></p>
            <p v-html="$t('policy.s8P2')"></p>
              <ul class="grid-pad mt-10">
                <li><p v-html="$t('policy.s8Li1')"></p></li>
                <li><p v-html="$t('policy.s8Li2')"></p></li>
                <li><p v-html="$t('policy.s8Li3')"></p></li>
              </ul>        
          </div>
          <div class="margeSection">
            <h2 class="txt-l f-primary" v-html="$t('policy.s9Title')"></h2>
            <p v-html="$t('policy.s9P1')"></p>
          </div>
          <div class="margeSection">
            <h2 class="txt-c" v-html="$t('policy.s10Title')"></h2>
            <p v-html="$t('policy.s10P1')"></p>
          </div>
        </div>
  
      </section>
  </div>
</template>

<style scoped>
  /*policy*/

  #contenu .margeSection {margin: 20px 0px;}
  #contenu .grid-pad {padding: 0px 200px;}
  #contenu p {font-size:18px; font-family: sans-serif; line-height: normal; text-align: justify;}
  #contenu h2 {padding: 20px 0px;}
  @media handheld, only screen and (max-width: 960px) {
    h1{font-size: 24px;}
    .grid-pad {padding: 0px 10px !important;}    
    .header {height: auto;}
    .bgBannerTop{background-position-y : 0px !important;}
    .section2TextSpacing {padding-right:0px;}
    .section2textTitle-64 {font-size: 24px;}
    .section3ParStyle { margin: 10px 10px 0px 10px; }
    .section3items { margin: 10px 0px 0px 0px; padding: 0px 10px 0px 10px; }
    .section3Title { font-size: 24px; margin: 0px; font-weight: 600; line-height: 58.12px; text-align: center; }
    .imgSquare { height: 25px !important; }
    p { font-size: 18px; }
    .section4Text { font-size: 24px; padding: 0px; }
    .section5TextSlogan2 { font-size: 24px; }
    .section5TextSlogan3 { font-size: 24px; }
  }
</style>

<script>
import translations from '../assets/I18N/policy.json';
export default {
  name: 'Policy',
  data: () => ({

  }),
  mounted() {
      this.$i18n.mergeLocaleMessage('fr', translations.fr);
      this.$i18n.mergeLocaleMessage('en', translations.en);
  },
  methods:{

  },
  components: {

  },
}
</script>