<template>
    <md-content>
      <div class="md-layout md-alignment-top-center mt-30">
        <div class="md-layout-item md-size-90">

          <div class="md-layout-item md-size-100">
            <h3>{{$t('title')}}</h3>
            <h5>{{$t('subTitle')}}</h5>
            <p class="mt-20" v-html="$t('description')"></p>
          </div>
          <div class="md-layout-item md-size-100 md-layout md-mt-5 imgSystem">
            <div class="md-layout-item md-small-size-100 mt-60">
              <h3>{{$t('system.title')}}</h3>
              <p class="mt-20" v-html="$t('system.description')"></p>
              </div>
            <div class="md-layout-item md-small-size-100 ">
              &nbsp;
              <img src="@/assets/Images/airbag/cartouche_moto_meca.jpg" />
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-layout md-mt-5 imgTurtle">
            <div class="md-layout-item md-small-size-100">
              &nbsp;
              <img src="@/assets/Images/airbag/eturtle_air_gonfle.jpg" />
              </div>
            <div class="md-layout-item md-small-size-100 mt-60">
              <h3>{{$t('turtle.title')}}</h3>
              <p class="mt-20" v-html="$t('turtle.description')"></p>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-layout md-mt-5 imgProtection">
            <div class="md-layout-item md-small-size-100 mt-60">
              <h3>{{$t('protection.title')}}</h3>
              <p class="mt-20" v-html="$t('protection.description')"></p>
              </div>
            <div class="md-layout-item md-small-size-100 ">
              &nbsp;
              <img src="@/assets/Images/airbag/turtle2_protection.jpg" />
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-layout md-mt-5 imgReusable">
            <div class="md-layout-item md-small-size-100">
              &nbsp;
              <img src="@/assets/Images/airbag/roadster.jpg" />
              </div>
            <div class="md-layout-item md-small-size-100 mt-60">
              <h3>{{$t('reusable.title')}}</h3>
              <p class="mt-20" v-html="$t('reusable.description')"></p>
            </div>
          </div>


        </div>
      </div>
    </md-content>
</template>

<i18n src="../../assets/I18N/airBag/mechanical.json"></i18n>
<style scoped>
  .content{
    max-width: 2000px;
    margin: 0 auto;
  }
  p {
  font-size: 16px;
  text-align: justify;
}

.imgTurtle, .imgSystem, .imgProtection, .imgReusable {
  width: 100%;
  min-height: 35em;
}

.imgSystem {
  background-image: url("@/assets/Images/airbag/cartouche_moto_meca.jpg"); background-position: top right; background-repeat: no-repeat; background-size:cover;
}
.imgTurtle {
  background-image: url("@/assets/Images/airbag/eturtle_air_gonfle.jpg"); background-position: top left; background-repeat: no-repeat; background-size:cover;
}
.imgProtection {
  background-image: url("@/assets/Images/airbag/turtle2_protection.jpg"); background-position: top right; background-repeat: no-repeat; background-size:cover;
}
.imgReusable {
  background-image: url("@/assets/Images/airbag/roadster.jpg"); background-position: top left; background-repeat: no-repeat; background-size:cover;
}

.imgSystem img, .imgTurtle img, .imgProtection img, .imgReusable img { display: none;}

@media handheld, only screen and (max-width: 1200px) {
  .imgSystem, .imgTurtle, .imgProtection, .imgReusable { min-height: 30em; }
}
@media handheld, only screen and (max-width: 959px) {
  .imgSystem, .imgTurtle, .imgProtection, .imgReusable { background-image:none; }
  .imgSystem img, .imgTurtle img, .imgProtection img, .imgReusable img { display: block; }
}
</style>

<script>
export default {
  name: 'Mechanical', 
  mounted: async function () {
    },
  components: {},
}
</script>